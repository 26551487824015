import { TableToolbar } from "../components/table/TableToolbar";
import { Box, Card, LinearProgress, Stack, Table, TableBody, TableContainer, TablePagination } from "@mui/material"
import { applyFilter, withTableHelper } from "../components/table/utils";
import { finishResearch, getEmployees, getFirstContacts, getProjects, getResearch, getStates, setCustomer } from "../api";
import { DATA_MODAL_COMPONENTS, DATA_MODAL_STATES } from "../components/dataModal/DataModal";
import { DefaultTable } from "../components/table/DefaultTable";
import { Iconify } from "../components/iconify";
import { store } from "../reducers/store";
import { useSnackbar } from "notistack";

const columnDef = [
    { id: 'id', disabled: true, label: 'ID', align: 'center' },
    { id: 'customer.name1', label: 'Kundenname 1', align: 'center', cellRenderer: ({ row }) => <a href={"https://www.google.com/search?q=" + row.customer.name1.split(" ").join("+")} target="_blank">{row.customer.name1} </a> },
    { id: 'customer.name2', label: 'Kundenname 2', align: 'center' },
    {
        id: "show", label: " ", cellRenderer: ({ row, extraCellProps }) => {
            const showDataModal = store((state) => state.appState.showDataModal);
            const user = store((state) => state.appState.user);

            return (
                <div onClick={() => {
                    showDataModal({
                        caption: "Kundendaten bearbeiten",
                        fields: [
                            { id: 'customerId', disabled: ({ config }) => config.modalState === DATA_MODAL_STATES.EDIT, label: 'Kundennummer', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'salutation', label: 'Anrede', required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.SELECT, selectValues: [{ value: "Herr", label: "Herr" }, { value: "Frau", label: "Frau" }] },
                            { id: 'name1', label: 'Name 1', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'name2', required: false, label: 'Name 2', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'zipCode', label: 'PLZ', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'city', label: 'Stadt', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'address', label: 'Adresse', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'country', label: 'Land', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'tel1', label: 'Telefon 1', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'tel2', required: false, label: 'Telefon 2', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'mobile', required: false, label: 'Mobil', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'email', required: false, label: 'E-Mail', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'contactPerson', required: false, label: 'Ansprechpartner', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'contactPersonPosition', required: false, label: 'Ansprechpartner (Position)', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'note', required: false, multiline: true, label: 'Notizen', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                            { id: 'lockedUntilDate', required: false, label: 'Gesperrt bis', align: 'center', comp: DATA_MODAL_COMPONENTS.DATE },
                            { id: 'onBlacklist', required: false, type: "checkbox", label: 'Dauerhaft gesperrt', align: 'center', comp: DATA_MODAL_COMPONENTS.CHECKBOX },
                        ],
                        onSave: ({ fieldValues }) => {
                            return finishResearch({ customer: fieldValues, researchId: row.id, employeeId: user.id })
                                .then(() => {
                                    extraCellProps.refreshData()
                                })
                                .then(() => {
                                    extraCellProps.enqueueSnackbar("Die Recherche wurde erfolgreich abgeschlossen.", { variant: "success" });
                                })
                                .catch((err) => {
                                    extraCellProps.enqueueSnackbar("Beim Abschluss der Recherche ist ein Fehler aufgetreten.", { variant: "warning" });
                                    return Promise.reject();
                                })
                        },
                        modalId: "edit_data",
                        values: row.customer
                    });
                }} style={{ display: "flex", cursor: "pointer", flexGrow: 1, justifyContent: "center", alignItems: "center" }}>
                    <Iconify icon="material-symbols:edit" />
                </div>
            )
        }, align: 'center'
    }
];

export const _Research = (props) => {
    const { filter, onFilterChange, onChangePage, onChangeRowsPerPage, data, page, rowsPerPage, refreshData } = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const dataForId = (id) => {
        return data.find(d => d.id === id);
    }

    return (
        <>
            <DefaultTable
                columnDef={columnDef}
                idKey={"id"}
                withSelection={false}
                extraCellProps={{ refreshData, enqueueSnackbar }}
                noDataCaption={"Alle Recherchen erledigt..."}
                {...props}
            />
            <TablePagination
                page={page}
                sx={{ minHeight: 50, maxHeight: 50 }}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                onPageChange={onChangePage}
                rowsPerPageOptions={[20, 30, 50]}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </>
    );
}


export const Research = withTableHelper(_Research, { loadData: getResearch })