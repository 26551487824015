import { Box, Card, CircularProgress, Stack } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";


export const DefaultViewWrapper = forwardRef(({ children }, ref) => {
    return (
        <Card style={{ display: "flex", flex: 1, flexDirection: "column", flexGrow: 1 }}>
            <div ref={ref} style={{ maxHeight: "100%", overflow: "auto", padding: 15 }}>
                {children}
            </div>
        </Card>
    )
})

export const DefaultViewWrapperTable = forwardRef(({ children }, ref) => {
    return (
        <Stack direction={"row"} flexGrow={1} flex={1} spacing={2} sx={{ pb: 2 }} maxHeight={"100%"} style={{ overflowY: "hidden" }}>
            <Card style={{ display: "flex", flex: 1, flexDirection: "column", flexGrow: 1 }}>
                <div style={{ maxHeight: "100%", minHeight: "100%", padding: 15 }}>
                    <Box style={{ maxHeight: "100%", minHeight: "100%", overflow: "hidden", display: "flex", flexDirection: "column" }}>
                        {children}
                    </Box>
                </div>
            </Card>
        </Stack>
    )
})

export const withLoader = (HoC, { loadData } = {}) => {
    return function PageLoaderHelper(props) {

        const [isLoading, setIsLoading] = useState(true);
        const [data, setData] = useState([]);

        useEffect(() => {
            if (loadData) {
                loadData()
                    .then((_data) => setData(_data))
                    .catch((err) => alert("Beim Laden der Ansicht ist ein Fehler aufgetreten."))
                    .finally(() => setIsLoading(false));
            }
        }, []);

        if (isLoading) {
            return (
                <Box sx={{ minHeight: "100%", maxHeight: "100%", minWidth: "100%", maxWidth: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            )
        }

        return (
            <HoC
                {...props}
                data={data}
            />
        )
    }
}