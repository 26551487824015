import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { store } from '../reducers/store';
import { Box, Button, Container, IconButton, Stack, Typography, alpha } from '@mui/material';
import { Iconify } from './iconify';
import Dashboard from '../routes/Dashboard';
import Logo from "../logo.png"
import { withWaitModal } from './wait';
import { logout } from '../api';
import { Task } from '../routes/Task';
import { Project } from '../routes/Project';
import { Employee } from '../routes/Employee';
import { State } from '../routes/State';
import { FirstContact } from '../routes/FirstContact';
import { Customer } from '../routes/Customer';
import { Research } from '../routes/Research';
import { Stats } from '../routes/Stats';

const NavItem = ({ item, menuCollapsed, subItem = false }) => {
    const active = store((state) => state.appState.menuItemKey === item.key);
    const changeRoute = store((state) => state.appState.changeRoute);
    const setAppState = store((state) => state.appState.setAppState);
    const user = store((state) => state.appState.user);

    const [collapsed, setCollapsed] = React.useState(false);

    const isDisabled = () => {
        if (!item.disabled) return false;
        if (typeof item.disabled === "function") return item.disabled();
        return !user[item.disabled];
    }

    return (
        <>
            <ListItemButton
                onClick={() => {
                    if (item.items) setCollapsed(!collapsed);
                    else if (item.onClick) item.onClick(setAppState);
                    else changeRoute(item.key);
                }}
                disabled={isDisabled()}
                sx={{
                    minHeight: 44,
                    borderRadius: 0.75,
                    typography: 'body2',
                    color: 'text.secondary',
                    textTransform: 'capitalize',
                    alignItems: "center",
                    display: "flex",
                    fontWeight: 'fontWeightMedium',
                    borderLeft: "3px solid rgb(24, 36, 51)",
                    ...(active && {
                        borderLeft: "3px solid rgb(32, 107, 196)",
                        color: 'primary.secondary',
                        // fontWeight: 'fontWeightSemiBold',
                        // bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                    }),
                }}
            >
                <Box sx={{ pl: subItem && !menuCollapsed ? 3 : 0, alignItems: "center", display: "flex", flexGrow: 1 }}>
                    <Box component="span" sx={{ width: 24, height: 24, mr: menuCollapsed ? 4 : 2, ml: menuCollapsed ? 1 : 0 }}>
                        {item.icon}
                    </Box>
                    <Box component="span" sx={{ color: "rgb(252, 253, 254)", display: "flex", flexGrow: 1 }}>{item.title} </Box>
                    {item.items && (
                        <Iconify icon={collapsed ? "mdi:chevron-up" : "mdi:chevron-down"} color="rgb(252, 253, 254)" />
                    )}
                </Box>
            </ListItemButton>
            {item.items && collapsed && item.items.map(i => <NavItem item={i} menuCollapsed={menuCollapsed} subItem={true} />)}
        </>
    );
}

export const menuItems = [
    { title: "Dashboard", icon: <Iconify color="rgb(252, 253, 254)" icon="material-symbols:dashboard" />, key: "dashboard", component: <Dashboard /> },
    { title: "Auftragsbearbeitung", icon: <Iconify color="rgb(252, 253, 254)" icon="material-symbols:task" />, key: "task", component: <Task /> },
    { title: "Kundenrecherche", disabled: "researchPermission", icon: <Iconify color="rgb(252, 253, 254)" icon="ph:magnifying-glass" />, key: "research", component: <Research /> },
    // { title: "Zeiterfassung", disabled: true, icon: <Iconify color="rgb(252, 253, 254)" icon="mingcute:time-fill" />, key: "time_tracking" },
    { title: "Auswertung", disabled: "admin", icon: <Iconify color="rgb(252, 253, 254)" icon="mdi:report-line" />, key: "report", component: <Stats /> },
    {
        title: "Administration", disabled: "admin", icon: <Iconify color="rgb(252, 253, 254)" icon="material-symbols:settings" />, key: "admin", items: [
            { title: "Mitarbeiter", disabled: "admin", icon: <Iconify color="rgb(252, 253, 254)" icon="material-symbols:support-agent" />, key: "employees", component: <Employee /> },
            { title: "Kunden", disabled: "admin", icon: <Iconify color="rgb(252, 253, 254)" icon="carbon:customer" />, key: "customer", component: <Customer /> },
            { title: "Projekte", disabled: "admin", icon: <Iconify color="rgb(252, 253, 254)" icon="material-symbols:contract" />, key: "projects", component: <Project /> },
            { title: "Abschlussstatus", disabled: "admin", icon: <Iconify color="rgb(252, 253, 254)" icon="material-symbols:checklist" />, key: "state", component: <State /> },
            { title: "Erstkontakte", disabled: "admin", icon: <Iconify color="rgb(252, 253, 254)" icon="fluent:people-chat-24-filled" />, key: "first_contact", component: <FirstContact /> },
        ]
    },
    {
        title: "Abmelden", icon: <Iconify color="rgb(252, 253, 254)" icon="material-symbols:logout" />, key: "logout", onClick: (setAppState) => {
            withWaitModal(() => {
                return logout().then(() => {
                    localStorage.clear();
                    setAppState("isLoggedIn", false);
                });
            })
        }
    },
]

export const AppMenu = () => {
    const setAppState = store((state) => state.appState.setAppState);
    const menuCollapsed = store((state) => state.appState.menuCollapsed);
    const user = store((state) => state.appState.user);

    return (
        <Stack direction={"column"} sx={{ overflowY: "auto", maxHeight: "100vh", minHeight: "100vh", backgroundColor: "rgb(24, 36, 51)", color: "rgb(252, 253, 254)" }}>
            <Stack sx={{ px: 2, mt: 1 }}>
                <div style={{ display: "flex", cursor: "pointer", flexGrow: 1, alignItems: "center", justifyContent: "flex-end" }} onClick={() => {
                    localStorage.setItem("menuCollapsed", String(!menuCollapsed));
                    setAppState("menuCollapsed", !menuCollapsed);
                }}>
                    <IconButton onClick={() => {
                        localStorage.setItem("menuCollapsed", String(!menuCollapsed));
                        setAppState("menuCollapsed", !menuCollapsed);
                    }}>
                        <Iconify color="rgb(252, 253, 254)" icon={menuCollapsed ? "mingcute:right-fill" : "mingcute:left-fill"} />
                    </IconButton>
                    {!menuCollapsed && (
                        <Typography variant='body2'>einklappen</Typography>
                    )}
                </div>
                {!menuCollapsed && (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant='h5' component={"h1"} sx={{ mt: 3 }}>
                            {user.project ? user.project.projectName.toUpperCase() : "WORTWECHSEL"}
                        </Typography>
                    </div>
                )}
            </Stack>
            <Stack component="nav" spacing={0.5} sx={{ px: menuCollapsed ? 0 : 2, mt: 3 }}>
                {menuItems.map((item) => (
                    <NavItem key={item.key} item={item} menuCollapsed={menuCollapsed} />
                ))}
            </Stack>
        </Stack>
    )
}