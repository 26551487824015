import { produce } from "immer";
import moment from 'moment';

export const createAppState = (set) => ({
    isLoggedIn: false,
    loading: {
        active: false,
        message: ""
    },
    showWait: false,
    menuCollapsed: localStorage.getItem("menuCollapsed") ? localStorage.getItem("menuCollapsed") === "true" : false,
    user: {},
    baseData: {},
    dataModals: [],
    dataModalConfigs: {},
    menuItemKey: "dashboard",
    menuItemProps: {},
    selectedTableCells: {},
    fieldValues: {},
    timeTrackingStart: null,
    timeTrackingRefresh: moment(),
    setAppState: (key, value) => {
        return set(produce((state) => {
            state.appState[key] = value;
        }))
    },
    changeRoute: (routeId, routeProps = {}) => {
        return set(produce((state) => {
            state.appState.menuItemKey = routeId;
            state.appState.menuItemProps = routeProps;
        }));
    },
    initCellSelection: (contextId) => {
        return set(produce((state) => {
            const newSelectedTableCells = { ...state.appState.selectedTableCells };
            newSelectedTableCells[contextId] = [];
            state.appState.selectedTableCells = newSelectedTableCells;
        }));
    },
    toggleCellSingle: (contextId, id) => {
        return set(produce((state) => {
            const newSelectedTableCells = { ...state.appState.selectedTableCells };
            newSelectedTableCells[contextId] = newSelectedTableCells[contextId].includes(id) ? [] : [id];
            state.appState.selectedTableCells = newSelectedTableCells;
        }));
    },
    toggleCell: (contextId, id) => {
        return set(produce((state) => {
            const newSelectedTableCells = { ...state.appState.selectedTableCells };
            newSelectedTableCells[contextId] = newSelectedTableCells[contextId].includes(id) ? state.appState.selectedTableCells[contextId].filter(c => c !== id) : [...newSelectedTableCells[contextId], id];
            state.appState.selectedTableCells = newSelectedTableCells;
        }));
    },
    selectCell: (contextId, id) => {
        return set(produce((state) => {
            const newSelectedTableCells = { ...state.appState.selectedTableCells };
            newSelectedTableCells[contextId] = [...newSelectedTableCells[contextId], id];
            state.appState.selectedTableCells = newSelectedTableCells;
        }));
    },
    unselectCell: (contextId, id) => {
        return set(produce((state) => {
            const contextSelectedCells = state.appState.selectedTableCells[contextId].filter(c => c !== id);
            const newSelectedTableCells = { ...state.appState.selectedTableCells };
            newSelectedTableCells[contextId] = contextSelectedCells;
            state.appState.selectedTableCells = newSelectedTableCells;
        }));
    },
    unselectAllCells: (contextId) => {
        return set(produce((state) => {
            const newSelectedTableCells = { ...state.appState.selectedTableCells };
            newSelectedTableCells[contextId] = [];
            state.appState.selectedTableCells = newSelectedTableCells;
        }));
    },
    showDataModal: (config) => {
        const modalId = config.modalId || "modal1";
        return set(produce((state) => {
            state.appState.dataModalConfigs = {
                ...state.appState.dataModalConfigs,
                [modalId]: config
            };
            state.appState.dataModals = [...state.appState.dataModals, modalId];
        }));
    },
    hideDataModal: (modalId) => {
        return set(produce((state) => {
            const newConfigs = { ...state.appState.dataModalConfigs };
            delete newConfigs[modalId];

            state.appState.dataModalConfigs = newConfigs;
            state.appState.dataModals = state.appState.dataModals.filter(m => m !== modalId);
            delete state.appState.fieldValues[modalId];
        }));
    },
    initFieldValues: (modalId, initialValues) => {
        return set(produce((state) => {
            console.log({ initialValues })
            state.appState.fieldValues[modalId] = initialValues;
        }));
    },
    setFieldValue: (modalId, fieldKey, value) => {
        return set(produce((state) => {
            state.appState.fieldValues[modalId][fieldKey] = value;
        }));
    }
});