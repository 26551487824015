import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import moment from "moment";
import { deDE } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deDE as gridDeDe } from '@mui/x-data-grid';
import { DataModal } from "./components/dataModal/DataModal";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import 'moment/locale/de';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';
import { Wait } from './components/wait';
import { DataModalRenderer } from './components/dataModal';

moment.locale('de');


const theme = createTheme(
    {
        palette: {
            primary: {
                main: "rgb(24, 36, 51)"
            },
            secondary: {
                main: 'rgb(32, 107, 196)'
            },
            success: {
                main: "#008000"
            },
            error: {
                main: "#e84141"
            },
            text: {
                primary: "rgb(24, 36, 51)",
            },
        },
        shadows: ["none"],
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        border: "1px solid #e9e9e9"
                    },
                },
            }
        },
        typography: {
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            button: {
                textTransform: 'none'
            }
        }
    },
    deDE,
    gridDeDe,
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <SnackbarProvider>
                <App />
                <DataModalRenderer />
                <Wait />
            </SnackbarProvider>
        </LocalizationProvider>
    </ThemeProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
