import { store } from './reducers/store';
import Login from './routes/Login';
import { CircularProgress, Typography } from '@mui/material';
import Home from './routes/Home';
import { useEffect, useState } from 'react';
import { getBaseData, getSession } from './api';
import { withWait } from "./components/wait"
import moment from 'moment';

import './App.css';
import './App.scss';
import './index.css';

function App() {
    const isLoggedIn = store((state) => state.appState.isLoggedIn);
    const setAppState = store((state) => state.appState.setAppState);
    const loading = store((state) => state.appState.loading);

    useEffect(() => {
        withWait(() => {
            return getSession()
                .then((res) => {
                    if (res.timeTrackings.length > 0) {
                        setAppState("timeTrackingStart", moment(res.timeTrackings[0].startTime));
                    }
                    return getBaseData().then((data) => {
                        setAppState("baseData", data);
                        setAppState("user", res);
                        setAppState("isLoggedIn", true);
                    });
                })
                .catch((err) => console.log({ err }))
        }, "Das DBMS wird vorbereitet. Bitte warten...")
    }, []);

    if (loading.active) {
        return (
            <div className='App-header'>
                <CircularProgress />
                <Typography variant="h6" sx={{ marginTop: 2, color: "black" }} >
                    {loading.message.length > 0 ? loading.message : "Bitte warten..."}
                </Typography>
            </div>
        )
    }

    if (isLoggedIn) {
        return <Home />
    } else {
        return <Login />
    }
}

export default App;
