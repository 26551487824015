import { Grid, Paper, Stack, TablePagination, Typography } from "@mui/material";
import { DATA_MODAL_COMPONENTS } from "../../../components/dataModal/DataModal";
import { withTableHelper } from "../../../components/table/utils";
import { TableToolbar } from "../../../components/table/TableToolbar";
import { DefaultTable } from "../../../components/table/DefaultTable";
import moment from "moment";

const columnDef = [
    { id: 'customerProjectPool.projectPool.project.description', label: 'Projekt', align: 'center' },
    { id: 'createdAt', label: 'Datum', cellValueFormatter: (value) => moment(value).format("DD.MM.YYYY HH:mm"), align: 'center', comp: DATA_MODAL_COMPONENTS.DATE, type: "date", },
    { id: 'employee.firstname', label: 'MA-Vorname', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'employee.lastname', label: 'MA-Nachname', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'firstContact.firstContactType', label: 'Erstkontakt', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'state.stateType', label: 'Abschlussstatus', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'projectDealType.dealType.dealType', label: 'Abschlussstatus', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'note', label: 'Notizen', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
];

const _CustomerHistoryTable = (props) => {
    const { filter, onFilterChange, onChangePage, onChangeRowsPerPage, data, page, rowsPerPage, refreshData } = props;

    const dataForId = (id) => {
        return data.find(d => d.id === id);
    }

    return (
        <>
            <DefaultTable
                {...props}
                columnDef={columnDef}
                idKey={"id"}
                withSelection={false}
            />
        </>
    );
}

export const CustomerHistoryTable = withTableHelper(_CustomerHistoryTable,)