import { Box, Checkbox, CircularProgress, IconButton, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { getDealTypes } from "../../../api";
import { DATA_MODAL_COMPONENTS, DATA_MODAL_STATES } from "../../../components/dataModal/DataModal";
import { FileUpload } from "../../../components/fileUpload";
import { Iconify } from "../../../components/iconify";
import { DefaultTable } from "../../../components/table/DefaultTable";
import { TableToolbar } from "../../../components/table/TableToolbar";
import { withTableHelper } from "../../../components/table/utils";
import { store } from "../../../reducers/store";

const columnDef = [
    { id: 'selection', label: '', align: 'center' },
    { id: 'id', label: 'P-Abschlussart-ID', align: 'center' },
    { id: 'dealType.dealType', label: 'Abschlusstyp', align: 'center', disabled: true, comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'description', label: 'Beschreibung', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'sendEmail', required: false, label: 'Email Aktiv?', align: 'center', type: "checkbox", comp: DATA_MODAL_COMPONENTS.CHECKBOX },
    { id: 'emailSubject', required: false, label: 'E-Mail-Betreff', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'emailTemplate', required: false, label: 'E-Mail-Template', align: 'center', comp: DATA_MODAL_COMPONENTS.WYSIWYG_EDITOR },
    { id: 'setCustomerBlocked', required: false, label: 'Kunde auf Blacklist?', align: 'center', type: "checkbox", comp: DATA_MODAL_COMPONENTS.CHECKBOX },
    { id: 'withResubmission', required: false, label: 'Mit WV?', align: 'center', type: "checkbox", comp: DATA_MODAL_COMPONENTS.CHECKBOX },
    { id: 'resubmissionDays', required: false, label: 'WV in wie viel Tagen?', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'resubmissionType', required: false, label: 'WV Typ', align: 'center', comp: DATA_MODAL_COMPONENTS.SELECT, selectValues: [{ value: "user", label: "Benutzer" }, { value: "admin", label: "Admins" }] },
    { id: 'note', required: false, label: 'Notizen', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD, multiline: true }
];

const _ProjectDealType = (props) => {
    const { filter, modalId, id, onFilterChange, onChangePage, onChangeRowsPerPage, page, rowsPerPage, refreshData, updateFieldValue } = props;

    const projectDealTypes = store((state) => state.appState.fieldValues[modalId][id]);

    const [loading, isLoading] = useState(true);

    useEffect(() => {
        if (projectDealTypes.length === 0) {
            getDealTypes().then((dealTypes) => {
                updateFieldValue(id, dealTypes.map((dT, index) => ({
                    id: -Math.abs(index + 1),
                    dealTypeId: dT.id,
                    dealType: dT
                })));
                isLoading(false);
            });
        } else {
            isLoading(false);
        }
    }, []);

    const dataForId = (id) => {
        return projectDealTypes.find(d => d.id === id);
    }

    const onSave = ({ fieldValues }) => {
        return new Promise((res, rej) => {
            if (!!fieldValues.id) {
                updateFieldValue(id, projectDealTypes.map(pdt => {
                    if (pdt.id === fieldValues.id) {
                        return {
                            ...fieldValues
                        }
                    }
                    return pdt;
                }));
            }

            res();
        });
    }

    if (loading) {
        return (
            <Paper sx={{ maxWidth: "100%", minWidth: "100%", p: 2, display: "flex", alignItems: "center", justifyContent: "center", flexGrow: 1 }}>
                <CircularProgress />
            </Paper>
        )
    }

    return (
        <Paper sx={{ maxWidth: "100%", minWidth: "100%", maxHeight: 400, overflow: "auto", p: 2 }}>
            <Typography variant="subtitle1" fontWeight={600} sx={{ mb: 2 }}>Projekt-Abschlussarten</Typography>
            <TableToolbar
                {...props}
                filterName={filter}
                placeholder={"Projekt-Abschlussart suchen..."}
                columnDef={columnDef}
                context={"Projekt-Abschlussart"}
                onSave={onSave}
                getDataForId={dataForId}
                modalId={"modal_project_deal_type"}
                withAdd={false}
            />
            <DefaultTable
                {...props}
                data={projectDealTypes}
                columnDef={columnDef}
                idKey={"id"}
                noData={projectDealTypes.length === 0}
                singleSelection
                showAll
            />
        </Paper>
    );
}

export const ProjectDealtType = withTableHelper(_ProjectDealType, { tableId: "project_deal_type_table", withDefaultWrapper: false })