import { store } from "../../reducers/store";
import { DataModal } from "./DataModal";


export const DataModalRenderer = () => {
    const dataModals = store((state) => state.appState.dataModals);

    if(dataModals.length === 0) return null;
    
    return dataModals.map((modalId) => {
        return <DataModal key={modalId} modalId={modalId} />
    })
}