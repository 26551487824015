import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { store } from "../../reducers/store";
import { DefaultViewWrapperTable } from "../DefaultViewWrapper";

export const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
};

export function emptyRows(page, rowsPerPage, arrayLength) {
    return page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

export function applyFilter({ inputData, getCompareValues, getCompareColumns, filter }) {
    if (filter) {
        inputData = inputData.filter(
            (row) => {
                if (getCompareValues) {
                    return getCompareValues(row).find((v) => String(v).toLowerCase().indexOf(filter.toLowerCase()) !== -1);
                } else if (getCompareColumns) {
                    return getCompareColumns(row).map(c => row[c]).find((v) => String(v).toLowerCase().indexOf(filter.toLowerCase()) !== -1);
                } else {
                    return false;
                }
            }
        );
    }

    return inputData;
}


export const withTableHelper = (HoC, { loadData, withDefaultWrapper = true, tableId = "defaultTable" } = {}) => {
    return function TableHelper(props) {
        const [data, setData] = useState([]);
        const [filter, setFilter] = useState("");
        const [loading, setLoading] = useState(true);
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(20);

        console.log({ props })

        const initCellSelection = store((state) => state.appState.initCellSelection);
        const unselectAllCells = store((state) => state.appState.unselectAllCells);
        const selectedTableCells = store((state) => state.appState.selectedTableCells);

        const _loadData = () => {
            if (props.loadedData) {
                setLoading(false);
                setData(props.loadedData);
            } else if (!loadData) {
                setLoading(false);
            } else {
                setLoading(true);
                return loadData().then((data) => setData(data)).finally(() => setLoading(false));
            }
        }

        useEffect(() => {
            _loadData()
            initCellSelection(tableId);

            return () => {
                unselectAllCells(tableId);
            }
        }, [props.loadedData]);

        const getSelectedCells = () => {
            return selectedTableCells[tableId];
        }

        const onFilterChange = (event) => {
            setPage(0);
            setFilter(event.target.value);
        };

        const onChangePage = (event, newPage) => setPage(newPage);

        const onChangeRowsPerPage = (event) => {
            setPage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
        };

        const dataFiltered = applyFilter({
            inputData: data,
            getCompareColumns: (row) => Object.keys(row),
            filter,
        });

        const noData = dataFiltered.length === 0 && !!loadData;

        if (loading) {
            return (
                <DefaultViewWrapperTable>
                    <LinearProgress sx={{ mb: 2 }} />
                </DefaultViewWrapperTable>
            )
        }

        const hocProps = {
            ...props,
            tableId,
            page,
            data: dataFiltered,
            onChangePage,
            onChangeRowsPerPage,
            rowsPerPage,
            onFilterChange,
            noData,
            filter,
            refreshData: _loadData,
            getSelectedCells
        }

        if (!withDefaultWrapper) {
            return (
                <HoC
                    {...hocProps}
                />
            )
        }

        return (
            <DefaultViewWrapperTable>
                <HoC
                    {...hocProps}
                />
            </DefaultViewWrapperTable>
        )
    }
}