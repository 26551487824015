import Toolbar from '@mui/material/Toolbar';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import { Iconify } from '../iconify';
import { IconButton, Stack } from '@mui/material';
import { store } from '../../reducers/store';
import { DATA_MODAL_STATES } from '../dataModal/DataModal';

export const TableToolbar = ({ numSelected, withAdd = true, withEdit = true, tableId, filterName, getDataForId, onSave, onFilterName, placeholder, context, onDelete, columnDef, customIconButtons = [], modalId = "modal1" }) => {
    const showDataModal = store((state) => state.appState.showDataModal);
    const selectedTableCells = store((state) => state.appState.selectedTableCells[tableId]);
    const unselectAllCells = store((state) => state.appState.unselectAllCells);

    const _onDelete = (params) => {
        return onDelete(params).then(() => {
            unselectAllCells(tableId);
        });
    }

    const _onSave = (params) => {
        return onSave(params).then(() => {
            unselectAllCells(tableId);
        })
    }

    return (
        <Toolbar
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter',
                }),
                columnGap: 4
            }}
            style={{ marginBottom: 5, padding: 0 }}
        >
            {onFilterName && (
                <OutlinedInput
                    value={filterName}
                    sx={{ fontSize: 14 }}
                    onChange={onFilterName}
                    placeholder={placeholder}
                    startAdornment={
                        <InputAdornment position="start">
                            <Iconify
                                icon="eva:search-fill"
                                sx={{ color: 'text.disabled', width: 20, height: 20 }}
                            />
                        </InputAdornment>
                    }
                    fullWidth
                />
            )}
            <Stack direction={"row"} flexGrow={1} justifyContent={"flex-end"} alignItems={"center"} spacing={2}>
                {withAdd && (
                    <IconButton onClick={() => showDataModal({ caption: context + " anlegen", fields: columnDef, onSave, modalId, modalState: DATA_MODAL_STATES.NEW })}>
                        <Iconify icon="material-symbols:add" width={30} />
                    </IconButton>
                )}
                {onDelete && (
                    <IconButton onClick={_onDelete} disabled={selectedTableCells.length === 0}>
                        <Iconify icon="material-symbols:delete" width={30} />
                    </IconButton>
                )}
                {withEdit && (
                    <IconButton
                        onClick={() => {
                            console.log({ item: getDataForId(selectedTableCells[0]) })
                            showDataModal({ caption: context + " bearbeiten", fields: columnDef, onSave: _onSave, values: getDataForId(selectedTableCells[0]), modalId, modalState: DATA_MODAL_STATES.EDIT })
                        }
                        }
                        disabled={selectedTableCells.length !== 1}>
                        <Iconify icon="material-symbols:edit" width={30} />
                    </IconButton>
                )}
                {customIconButtons.map(b => {
                    return (
                        <IconButton onClick={b.onClick} disabled={b.isDisabled ? b.isDisabled(selectedTableCells.length) : false}>
                            <Iconify icon={b.icon} width={30} />
                        </IconButton>
                    )
                })}
            </Stack>
        </Toolbar>
    );
}