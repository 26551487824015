import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { getEmployees, getProjectDealTypeStats, getProjects, getProjectStats, getStats, getStatsExcel } from "../api";
import { withWaitModal } from "../components/wait";
import { Iconify } from "../components/iconify";
import { TextItem } from "./components/task/TextItem";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

ChartJS.defaults.datasets.bar.maxBarThickness = 30;

const createOptions = (title) => {
    return {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: '',
            },
            title: {
                display: true,
                text: title
            },
        },
        yAxes: [
            {
                ticks: {
                    precision: 0,
                },
            },
        ],
    };
}

const createChartData = (stats) => {
    return {
        labels: Object.keys(stats),
        datasets: [
            {
                label: '',
                data: Object.keys(stats).map((s) => stats[s].length),
                borderColor: 'rgb(24, 36, 51)',
                backgroundColor: 'rgb(24, 36, 51, 0.7)',
            },
        ],
    }
};

const createPieChartData = ({ pool, all = false }) => {
    const data = all ? pool.reduce((data, p) => {
        const newData = data;

        Object.keys(p).forEach(key => {
            if (typeof p[key] === "number") {
                newData[key] = p[key] + (newData[key] || 0)
            }
        })

        return newData;
    }, {}) : pool;

    console.log({ data });

    return {
        labels: ["Anzahl Kunden", "Kunden auf Blacklist", "Kunden abgeschlossen", "Restkunden"],
        datasets: [
            {
                label: all ? "Alle Pools" : pool.pool.poolName,
                data: Object.keys(data).map(key => data[key]).filter(v => typeof v === "number"),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    //   'rgba(153, 102, 255, 0.2)',
                    //   'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    //   'rgba(153, 102, 255, 1)',
                    //   'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }
};

export const Stats = () => {

    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState(moment());
    const [projects, setProjects] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [employeeStats, setEmployeeStats] = useState();
    const [selectedProject, setSelectedProject] = useState();
    const [projectDealTypeStats, setProjectDealTypeStats] = useState();
    const [projectStats, setProjectStats] = useState();
    const [selectedPool, setSelectedPool] = useState("all");


    useEffect(() => {
        Promise.all([getProjects(), getEmployees()]).then((data) => {
            setProjects(data[0]);
            setEmployees(data[1]);
        });
    }, []);

    useEffect(() => {
        if (selectedStartDate && selectedProject) {
            withWaitModal(() => {
                return getProjectDealTypeStats({ projectId: selectedProject, startDate: selectedStartDate.format(), endDate: selectedEndDate?.format() }).then(data => {
                    setProjectDealTypeStats(data);
                });
            });
        }
    }, [selectedStartDate, selectedEndDate]);

    useEffect(() => {
        if (selectedEmployee) {
            withWaitModal(() => {
                return getProjectDealTypeStats({ projectId: selectedProject, employeeId: selectedEmployee, startDate: selectedStartDate, endDate: selectedEndDate }).then(data => {
                    setEmployeeStats(data);
                });
            });
        }
    }, [selectedEmployee, selectedStartDate, selectedEndDate, selectedProject]);

    useEffect(() => {
        if (selectedProject) {
            withWaitModal(() => {
                return getProjectStats({ projectId: selectedProject }).then(data => {
                    setProjectStats(data);
                });
            });
        }
    }, [selectedProject]);

    return (
        <Grid height={"100%"} container spacing={1} overflow={"auto"}>
            <Grid item xs={12} md={4} lg={4}>
                <Grid container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflow: "auto" }}>
                            <Typography variant='h6'>
                                Einstellungen
                            </Typography>
                            <FormControl size="small" sx={{ mt: 2, mb: 2 }} fullWidth>
                                <InputLabel>Projekt</InputLabel>
                                <Select
                                    label={"Projekt"}
                                    value={selectedProject}
                                    onChange={(e) => setSelectedProject(e.target.value)}
                                >
                                    {projects.map(p => {
                                        return (
                                            <MenuItem value={p.id}>{p.projectName}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <DatePicker slotProps={{ textField: { size: 'small' } }} maxDate={moment()} sx={{ mb: 2 }} label={"Von (Datum)"} disabled={!selectedProject} value={selectedStartDate} onChange={(v) => setSelectedStartDate(v)} />
                            <DatePicker slotProps={{ textField: { size: 'small' } }} maxDate={moment()} label={"Bis (Datum)"} disabled={!selectedProject || !selectedStartDate} value={selectedEndDate} onChange={(v) => setSelectedEndDate(v)} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflow: "auto" }}>
                            <Typography variant='h6' sx={{ mb: 3 }}>
                                Projektübersicht
                            </Typography>
                            {!projectStats ? (
                                <Typography variant='body2'>
                                    Bitte Projekt auswählen
                                </Typography>
                            ) : (
                                <>
                                    <FormControl size="small" sx={{ mb: 2 }} fullWidth>
                                        <InputLabel>Poolauswahl</InputLabel>
                                        <Select
                                            label={"Poolauswahl"}
                                            value={selectedPool}
                                            onChange={(e) => setSelectedPool(e.target.value)}
                                        >
                                            <MenuItem value="all">Alle</MenuItem>
                                            {projectStats.map(p => {
                                                return (
                                                    <MenuItem value={p.pool.id}>{p.pool.poolName}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    <Pie data={createPieChartData(selectedPool === "all" ? { pool: projectStats, all: true } : { pool: projectStats.find(p => p.pool.id === selectedPool) })} />
                                </>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={8} xl={8}>
                <Grid container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflow: "auto" }}>
                            <Stack direction={"row"} alignItems={"center"} flexGrow={1} sx={{ mb: 3 }}>
                                <Typography variant='h6' sx={{ flexGrow: 1 }}>
                                    Übersicht Abschlussarten
                                </Typography>
                                <Button onClick={() => {
                                    withWaitModal(() => {
                                        let fileName = `${projects.find(p => p.id === selectedProject).projectName}-${moment(selectedStartDate).format("DDMMYYYY")}${selectedEndDate ? "-" + moment(selectedEndDate).format("DDMMYYYY") : ""}.xlsx`
                                        return getStatsExcel({ projectId: selectedProject, startDate: selectedStartDate?.format(), endDate: selectedEndDate?.format() }).then((data) => {
                                            const url = window.URL.createObjectURL(data);
                                            const anchorTag = document.createElement('a');
                                            anchorTag.href = url;
                                            anchorTag.download = fileName;
                                            anchorTag.click();
                                            window.URL.revokeObjectURL(url);
                                        });
                                    })
                                }} size="small" startIcon={<Iconify icon="icon-park-outline:excel" />} sx={{ mb: 2 }} variant="outlined" color="secondary">Export</Button>
                            </Stack>
                            {projectDealTypeStats ? (
                                <Bar options={createOptions('Auswertung für ' + moment(selectedStartDate).format("ddd DD.MM.YYYY") + ' bis ' + moment(selectedEndDate).format("ddd DD.MM.YYYY"))} data={createChartData(projectDealTypeStats)} />
                            ) : (
                                <Typography variant='body2'>
                                    Bitte Projekt und Zeitraum auswählen
                                </Typography>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflow: "auto" }}>
                            <Typography variant='h6' sx={{ mb: 3 }}>
                                Leistungsübersicht Mitarbeiter
                            </Typography>
                            <FormControl size="small" sx={{ mb: 2 }} fullWidth>
                                <InputLabel>Auswahl Mitarbeiter</InputLabel>
                                <Select
                                    label={"Auswahl Mitarbeiter"}
                                    value={selectedEmployee}
                                    onChange={(e) => setSelectedEmployee(e.target.value)}
                                >
                                    {employees.map(e => {
                                        return (
                                            <MenuItem value={e.id}>{e.firstname} {e.lastname}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            {employeeStats && (
                                <Bar options={createOptions("Mitarbeiterauswertung")} data={createChartData(employeeStats)} />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}