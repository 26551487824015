import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getCustomers } from "../../../api";
import { store } from "../../../reducers/store";

export const ProjectOwner = (props) => {
    const { modalId, id, updateFieldValue } = props;

    const customer = store((state) => state.appState.fieldValues[modalId][id]);

    const [open, setOpen] = useState(false);
    const [dataOptions, setDataOptions] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log({ searchString })
        if (searchString && searchString.length > 2) {
            setLoading(true);

            (async () => {
                const result = await getCustomers(searchString);

                setDataOptions(result);
                setLoading(false);
            })();
        }
    }, [searchString]);

    useEffect(() => {
        if (!open) {
            setDataOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            sx={{ width: "100%" }}
            open={open}
            onOpen={() => setOpen(true)}
            value={customer}
            onChange={(e, value) => updateFieldValue(id, value)}
            onClose={() => setOpen(false)}
            onInputChange={(e, value) => setSearchString(value)}
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.name1 + " " + option.name2}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            loading={loading}
            options={dataOptions}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Kunde des Projekts"
                    required
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <div>
                                {loading ? (
                                    <CircularProgress color="primary" size={30} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </div>
                        ),
                    }}
                />
            )}
        />
    );
}