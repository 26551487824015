import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { Iconify } from "../../../components/iconify";

export const InfoModal = ({ closeModal, data, dataTtitleKey, dataMessageKey, caption }) => {
    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ p: 2, borderBottom: "1px solid #e9e9e9" }}>
                <Typography variant="h6" fontWeight={600}>
                    {caption}
                </Typography>
                <IconButton onClick={closeModal}>
                    <Iconify icon="material-symbols:close" width={25} />
                </IconButton>
            </Stack>
            <Box display={"flex"} flexDirection={"column"} overflow={"auto"} flexGrow={1} sx={{ p: 2, overflow: "auto" }}>
                <table style={{ borderCollapse: "separate", borderSpacing: 20 }}>
                    {data.map(d => {
                        return (
                            <tr>
                                <td>{d[dataTtitleKey]}</td>
                                <td>{d[dataMessageKey]}</td>
                            </tr>
                        )
                    })}
                </table>
            </Box>
            <Stack direction={"row"} columnGap={1} alignItems="center" justifyContent={"flex-end"} sx={{ p: 2, borderTop: "1px solid #e9e9e9" }}>
                <Button variant='outlined' color="secondary" onClick={closeModal} size='small'>Schließen</Button>
            </Stack>
        </>
    );
}