import { Box, Button, Checkbox, CircularProgress, Fab, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TablePagination, Typography } from "@mui/material";
import moment from "moment";
import { cancelTask, finishResubmission, finishTask, getGuidelines, getHasActiveTask, getObjections, getProjectDealTypes, getProjects, getTask, setResubmission } from "../../../api";
import { useSnackbar } from "notistack";
import { InfoModal } from "./InfoModal";
import { withWaitModal } from "../../../components/wait";
import { DATA_MODAL_COMPONENTS, DATA_MODAL_STATES } from "../../../components/dataModal/DataModal";
import { store } from "../../../reducers/store";
import { Iconify } from "../../../components/iconify";
import { withCustomModal } from "../../../components/modal/Modal";

const MenuButton = ({ icon, caption, onClick }) => {
    return (
        <Button
            onClick={onClick}
            sx={{ textWrap: "nowrap" }}
            startIcon={<Iconify icon={icon} width={20} />}
        >{caption}</Button>
    )
}

export const _MenuBar = ({ init, finished, getTask, resubmissionId, context, showModal, closeModal, task, selectedProject }) => {

    const showDataModal = store((state) => state.appState.showDataModal);
    const baseData = store((state) => state.appState.baseData);
    const user = store((state) => state.appState.user);
    const changeRoute = store((state) => state.appState.changeRoute);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const _finishTask = ({ fieldValues }) => {
        return new Promise((res, rej) => {
            return finishTask({
                ...fieldValues,
                employeeId: user.id,
                customerId: task.customerId,
                customerProjectPoolId: task.id,
                projectId: task.projectPool.project.id
            })
                .then(() => getTask())
                .then(() => {
                    enqueueSnackbar("Der Abschluss wurde erfolgreich gespeichert.", { variant: "success" });
                    res();
                })
                .catch((err) => {
                    console.log({ err });
                    enqueueSnackbar("Beim Speichern des Abschluss ist ein Fehler aufgetreten.", { variant: "error" });
                    rej();
                });
        });
    }

    const isADealType = ({ value, depValue, dealTypes }) => {
        if (!depValue) return false;

        for (const dealTypeId of depValue) {
            if (dealTypes.find(dT => dT.id === dealTypeId).dealType.dealType.includes("A")) {
                return true;
            }
        }

        return false;
    }

    return (
        <Paper sx={{ p: 1, overflow: "auto", zIndex: 10, mb: 2, display: "flex", alignItems: "center", overflowY: "hidden" }}>
            <Stack direction={"row"} alignItems={"center"} columnGap={2} overflowX={"auto"}>
                {task.isResubmission && (
                    <MenuButton onClick={() => {
                        if (window.confirm("Möchten Sie die WV als erledigt markieren?")) {
                            withWaitModal(() => {
                                return finishResubmission(task).then(() => {
                                    changeRoute("dashboard")
                                }).catch((err) => {
                                    enqueueSnackbar("Beim Abschluss der Wiedervorlage ist ein Fehler aufgetreten.", { variant: "error" });
                                })
                            });
                        }
                    }} caption={"WV erledigt"} icon={"material-symbols:check"} />
                )}
                <MenuButton onClick={() => {
                    withWaitModal(() => {
                        return getProjectDealTypes(selectedProject).then(dealTypes => {
                            showDataModal({
                                caption: "Abschluss erstellen",
                                fields: [
                                    { id: 'finishStateId', label: 'Abschlussstatus', required: true, align: 'center', comp: DATA_MODAL_COMPONENTS.SELECT, selectValues: baseData.states.map(s => ({ value: s.id, label: s.stateType, disabled: s.isResearch && !!task.customer.research })) },
                                    {
                                        id: 'projectDealTypeId', multiple: true, defaultValue: [], label: 'Abschluss-Index', required: ({ depValue }) => !!depValue && !!baseData.states.find(s => s.id === depValue).isFinished, dependency: "finishStateId", visible: ({ value, depValue }) => !!depValue && !!baseData.states.find(s => s.id === depValue).isFinished, align: 'center', comp: DATA_MODAL_COMPONENTS.SELECT, selectValues: dealTypes.map(s => ({
                                            value: s.id, label: s.dealType.dealType + " - " + s.description, labelComp: (
                                                <Stack maxWidth={"100%"} sx={{ wordBreak: "break-word", overflow: "hidden" }} direction={"column"} spacing={1}>
                                                    <Typography variant="body2">{s.dealType.dealType + " - " + s.description}</Typography>
                                                    {s.note && (
                                                        <Typography sx={{ wordBreak: "break-word" }} noWrap variant="body2" color="grey">{s.note}</Typography>
                                                    )}
                                                </Stack>
                                            )
                                        }))
                                    },
                                    { id: 'firstContactId', label: 'Erstkontakt', dependency: "finishStateId", visible: ({ value, depValue }) => !!depValue && !!baseData.states.find(s => s.id === depValue).isFinished, required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.SELECT, selectValues: baseData.firstContacts.map(s => ({ value: s.id, label: s.firstContactType })) },
                                    { id: 'deliveryKW', label: 'Lieferwoche', required: false, dependency: "projectDealTypeId", visible: ({ depValue }) => isADealType({ depValue, dealTypes }), align: 'center', comp: DATA_MODAL_COMPONENTS.SELECT, selectValues: [...Array(20).keys()].map(s => ({ value: moment().add(s, "weeks").isoWeek(), label: moment().add(s, "weeks").isoWeek() })) },
                                    { id: 'note', label: 'Notizen', dependency: "finishStateId", visible: ({ value, depValue }) => !!depValue && !!baseData.states.find(s => s.id === depValue).isFinished, required: false, multiline: true, align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                ],
                                onSave: _finishTask,
                                modalId: "abschluss",
                                modalState: DATA_MODAL_STATES.NEW
                            });
                        });
                    })
                }} caption={"Abschluss erstellen"} icon={"material-symbols:check"} />
                <MenuButton onClick={() => {
                    if (window.confirm("Möchten Sie den aktuell Auftrag überspringen? Diese Aktion ist täglich auf x limitiert.")) {
                        getTask(true);
                    }
                }} caption={"Überspringen"} icon={"bi:skip-end"} />
                <MenuButton onClick={() => {
                    showDataModal({
                        caption: "Wiedervorlage erstellen",
                        fields: [
                            { id: 'date', label: 'WV-Datum', align: 'center', future: true, comp: DATA_MODAL_COMPONENTS.DATE_TIME },
                        ],
                        onSave: ({ fieldValues }) => {
                            return setResubmission({ date: fieldValues.date, employeeId: user.id, customerProjectPoolId: task.id })
                                .then(() => getTask());
                        },
                        modalId: "abschluss",
                        modalState: DATA_MODAL_STATES.NEW
                    });
                }} caption={"WV"} icon={"material-symbols:alarm"} />
                <MenuButton onClick={() => {
                    withWaitModal(() => {
                        return getGuidelines().then((data) => {
                            showModal(<InfoModal closeModal={closeModal} data={data} dataMessageKey={"guideline"} dataTtitleKey={"guidelineTitle"} caption={"Leitfaden"} />)
                        });
                    });
                }} caption={"Leitfaden"} icon={"mdi:talk"} />
                <MenuButton onClick={() => {
                    withWaitModal(() => {
                        return getObjections().then((data) => {
                            showModal(<InfoModal closeModal={closeModal} data={data} dataMessageKey={"objectionAction"} dataTtitleKey={"objectionTitle"} caption={"Einsprüche"} />)
                        });
                    });
                }} caption={"Einwand"} icon={"mdi:thunder"} />
                <MenuButton onClick={() => {

                }} caption={"Anweisungen"} icon={"mdi:help-outline"} />
                <MenuButton onClick={() => {
                    if (window.confirm("Möchten Sie den aktuell Auftrag für die Bearbeitung freigeben?")) {
                        withWaitModal(() => {
                            return cancelTask(task.id)
                                .then(() => init())
                                .catch((err) => {
                                    alert("Bei der Aktion ist ein Fehler aufgtreten.");
                                    return Promise.reject();
                                });
                        })
                    }
                }} caption={"Abbrechen"} icon={"material-symbols:cancel"} />
            </Stack>
        </Paper>
    )
}

export const MenuBar = withCustomModal(_MenuBar);