import { Box, Button, Checkbox, Chip, CircularProgress, Fab, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TablePagination, Typography } from "@mui/material";
import { DATA_MODAL_COMPONENTS, DATA_MODAL_STATES, defaultModalStyle } from "../components/dataModal/DataModal";
import { withTableHelper } from "../components/table/utils";
import { TableToolbar } from "../components/table/TableToolbar";
import { DefaultTable } from "../components/table/DefaultTable";
import { ContactTable } from "./components/task/ContactTable";
import { CustomerHistoryTable } from "./components/task/CustomerHistoryTable";
import { Iconify } from "../components/iconify";
import moment from "moment";
import { store } from "../reducers/store";
import { withWaitModal } from '../components/wait';
import { cancelTask, customerDataModified, finishTask, getGuidelines, getHasActiveTask, getObjections, getProjectDealTypes, getProjects, getTask, setCustomer, setResubmission } from "../api";
import { useEffect, useState } from "react";
import { Call } from "@mui/icons-material";
import { unstable_batchedUpdates } from "react-dom";
import { MESSAGE_TYPES, withSnackbar } from "../components/snackbar/util";
import { useSnackbar } from "notistack";
import { withCustomModal } from "../components/modal/Modal";
import { TextItem } from "./components/task/TextItem";
import { InfoModal } from "./components/task/InfoModal";
import { MenuBar } from "./components/task/MenuBar";

export const Task = ({ context = "task", resubmissionId }) => {
    const showDataModal = store((state) => state.appState.showDataModal);
    const user = store((state) => state.appState.user);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [task, setTask] = useState();
    const [loading, setLoading] = useState(true);
    const [projectSelection, setProjectSelection] = useState(false);
    const [selectedProject, setSelectedProject] = useState(localStorage.getItem("last_project") ? Number(localStorage.getItem("last_project")) : null);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        if (user.project) {
            setSelectedProject(user.project.id);
        }
    }, [user])

    useEffect(() => {
        if (context === "task") {
            init();
        } else if (context === "resubmission") {
            getTask({ employeeId: user.id, resubmissionId }).then((task) => {
                setTask(task);
                setProjectSelection(false);
                setLoading(false);
            });
        }
    }, []);

    const init = (skip = false) => {
        setLoading(true);
        Promise.all([getHasActiveTask(user.id), getProjects()])
            .then((data) => {
                console.log({ data, selectedProject })
                const hasTask = data[0];
                const projects = data[1];

                if (hasTask && selectedProject) {
                    getTask({ employeeId: user.id, skip, projectId: parseInt(selectedProject) }).then((task) => {
                        setTask(task);
                        setProjectSelection(false);
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                    setProjectSelection(true);
                    setProjects(projects);
                }
            });
    }

    const _getTask = (skip = false) => {
        return getTask({ employeeId: user.id, skip, projectId: parseInt(selectedProject) })
            .then((data) => {
                if (Object.keys(data).length === 0) {
                    setTask();
                    setProjectSelection(true);
                    setLoading(false);
                    enqueueSnackbar("Aktuell sind für das Projekt keine Aufträge vorhanden.", { variant: "warning" });
                    return;
                }
                setTask(data);
                setProjectSelection(false);
                setLoading(false);
            })
    }

    if (loading) {
        return (
            <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                <CircularProgress />
            </Box>
        )
    }

    if (projectSelection) {
        return (
            <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                <Paper sx={{ p: 2, width: "50%", zIndex: 10, display: "flex", flexDirection: "column" }}>
                    <FormControl
                        fullWidth
                        disabled={!!user.project}
                    >
                        <InputLabel>Projekt auswählen</InputLabel>
                        <Select
                            label={"Projekt auswählen"}
                            onChange={(e) => setSelectedProject(e.target.value)}
                            value={selectedProject}
                        >
                            {projects.filter(p => !!p.active).map(p => {
                                return (
                                    <MenuItem value={p.id}>{p.projectName}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <Button sx={{ mt: 2 }} variant='contained' color="secondary" size='small' onClick={() => {
                        localStorage.setItem("last_project", String(selectedProject));
                        _getTask();
                    }}>Anwenden</Button>
                </Paper>
            </Box>
        )
    }

    return (
        <Box sx={{ p: 2, display: 'flex', width: "100%", height: "100%", flexDirection: 'column', overflow: "auto" }}>
            {task.isResubmission && (
                <Box sx={{ pb: 1, pt: 1, overflow: "auto", zIndex: 10, display: "flex", alignItems: "center", overflowY: "hidden" }}>
                    <Chip label="Wiedervorlage" color="secondary" />
                </Box>
            )}
            <MenuBar init={init} getTask={_getTask} resubmissionId={resubmissionId} finished={task.finished} context={context} task={task} selectedProject={selectedProject} />
            <Grid container spacing={3} overflow={"auto"}>
                <Grid item xs={12} sm={4} xl={4}>
                    <Paper sx={{ p: 2, display: 'flex', height: "100%", flexDirection: 'column', overflow: "auto" }}>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant='body2' fontWeight={600}>
                                    Stammdaten
                                </Typography>
                            </Box>
                            <IconButton onClick={() => {
                                showDataModal({
                                    caption: "Kundendaten bearbeiten",
                                    fields: [
                                        { id: 'customerId', label: 'Kundennummer', disabled: true, required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'name1', label: 'Name 1', required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'name2', label: 'Name 2', required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'salutation', label: 'Anrede', required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.SELECT, selectValues: [{ value: "Herr", label: "Herr" }, { value: "Frau", label: "Frau" }] },
                                        { id: 'contactPerson', required: false, label: 'Ansprechpartner', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'contactPersonPosition', required: false, label: 'Ansprechpartner (Position)', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'address', label: 'Adresse', required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'zipCode', label: 'PLZ', required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'city', label: 'Stadt', required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'country', label: 'Land', required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'tel1', label: 'Telefon 1', required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'tel2', label: 'Telefon 2', required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'mobile', label: 'Mobil', required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'email', label: 'E-Mail', required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                        { id: 'note', label: 'Notizen', required: false, align: 'center', multiline: true, comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
                                    ],
                                    onSave: ({ fieldValues }) => {
                                        return Promise.all([setCustomer(fieldValues), customerDataModified({ customer: task.customer, employeeId: user.id })])
                                            .then(() => {
                                                return _getTask();
                                            })
                                            .catch((err) => {
                                                enqueueSnackbar("Beim Speichern des Kunden ist ein Fehler aufgetreten.", { variant: "error" });
                                                return Promise.reject();
                                            });
                                    },
                                    modalId: "edit_data",
                                    values: task?.customer
                                });
                            }}>
                                <Iconify icon="material-symbols:edit" />
                            </IconButton>
                        </Stack>
                        <table style={{ borderCollapse: "separate", borderSpacing: "0px 10px" }}>
                            <TextItem caption={"Kdnr"} value={task?.customer.customerId} />
                            <TextItem caption={"Name 1"} value={task?.customer.name1} />
                            <TextItem caption={"Name 2"} value={task?.customer.name2} />
                            <TextItem caption={"Anrede"} value={task?.customer.salutation} />
                            <TextItem caption={"Asp"} value={task?.customer.contactPerson} />
                            <TextItem caption={"Asp Position"} value={task?.customer.contactPersonPosition} />
                            <TextItem caption={"Adresse"} value={task?.customer.address} />
                            <TextItem caption={"Stadt"} value={`${task?.customer.zipCode} ${task?.customer.city}`} />
                            <TextItem caption={"Land"} value={task?.customer.country} />
                            <TextItem caption={"Telefon 1"} value={task?.customer.tel1} />
                            <TextItem caption={"Telefon 2"} value={task?.customer.tel2} />
                            <TextItem caption={"Mobil"} value={task?.customer.mobile} />
                            <TextItem caption={"E-Mail"} value={task?.customer.email} />
                            <TextItem caption={"Notizen"} value={task?.customer.note} />
                        </table>
                    </Paper>
                </Grid>
                <Grid item container xs={12} sm={8} xl={8} spacing={3}>
                    <Grid item xs={12} sm={6} xl={6}>
                        <Paper sx={{ p: 2, display: 'flex', height: "100%", flexDirection: 'column', overflow: "auto" }}>
                            <Typography variant='body2' fontWeight={600}>
                                Projekt/Pool
                            </Typography>
                            <table style={{ borderCollapse: "separate", borderSpacing: "0px 10px" }}>
                                <TextItem caption={"P-Name"} value={task?.projectPool.project.projectName} />
                                <TextItem caption={"P-Beschreibung"} value={task?.projectPool.project.description} />
                                <TextItem caption={"P-Kunde"} value={task?.projectPool.project.customer.name1 + " " + task?.projectPool.project.customer.name2} />
                                <TextItem caption={"Pool-Name"} value={task?.projectPool.poolName} />
                            </table>
                        </Paper>
                    </Grid>
                    {/* <Grid item xs={12} sm={4} xl={4}>
                        <Paper sx={{ p: 2, height: "100%", display: 'flex', flexDirection: 'column', overflow: "auto" }}>
                            <Typography variant='body2' fontWeight={600}>
                                Wiedervorlage
                            </Typography>
                            <table style={{ borderCollapse: "separate", borderSpacing: "0px 10px" }}>
                                <tr>
                                    <td style={{ verticalAlign: "middle" }}>
                                        <Typography variant="body2">
                                            WV aktiv
                                        </Typography>
                                    </td>
                                    <td style={{ verticalAlign: "top", textAlign: task?.resubmission ? "left" : "center" }}>
                                        <Checkbox sx={{ p: 0 }} disabled color="success" checked={!!task?.resubmission} />
                                    </td>
                                </tr>
                                <TextItem textAlign={task?.resubmission ? "left" : "center"} caption={"WV Datum"} value={task?.resubmission ? moment(task?.resubmission?.resubmissionDate).format("DD.MM.YYYY") : "-"} />
                                <TextItem textAlign={task?.resubmission ? "left" : "center"} caption={"WV Uhrzeit"} value={task?.resubmission ? moment(task?.resubmission?.resubmissionDate).format("hh:mm") : "-"} />
                                <TextItem textAlign={task?.resubmission ? "left" : "center"} caption={"WV Mitarbeiter"} value={task?.resubmission ? task?.resubmission?.employee.firstname + " " + task?.resubmission?.employee.lastname : "-"} />
                                <TextItem textAlign={task?.resubmission ? "left" : "center"} caption={"WV erstellt am"} value={task?.resubmission ? moment(task?.resubmission?.createdAt).format("DD.MM.YYYY") : "-"} />
                            </table>
                        </Paper>
                    </Grid> */}
                    <Grid item xs={12} sm={6} xl={6}>
                        <Paper sx={{ p: 2, height: "100%", display: 'flex', flexDirection: 'column', height: "100%", overflow: "auto" }}>
                            <Typography variant='body2' fontWeight={600}>
                                Recherche
                            </Typography>
                            <table style={{ borderCollapse: "separate", borderSpacing: "0px 10px" }}>
                                <tr>
                                    <td style={{ verticalAlign: "middle" }}>
                                        <Typography variant="body2">
                                            In Recherche
                                        </Typography>
                                    </td>
                                    <td style={{ verticalAlign: "top", textAlign: task?.customer.research ? "left" : "center" }}>
                                        <Checkbox sx={{ p: 0 }} disabled color="success" checked={!!task?.customer.research && !task.customer.research.doneDate} />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "middle" }}>
                                        <Typography variant="body2">
                                            Bereits recherchiert
                                        </Typography>
                                    </td>
                                    <td style={{ verticalAlign: "top", textAlign: task?.customer.research ? "left" : "center" }}>
                                        <Checkbox sx={{ p: 0 }} disabled color="success" checked={!!task?.customer.research?.doneDate} />
                                    </td>
                                </tr>
                                <TextItem textAlign={task?.customer.research ? "left" : "center"} caption={"Recherche-Mitarbiter"} value={task?.customer.research ? task?.customer.research?.employee.firstname + " " + task?.customer.research?.employee.lastname : "-"} />
                                <TextItem textAlign={task?.customer.research ? "left" : "center"} caption={"Recherche-Datum"} value={task?.customer.research?.doneDate ? moment(task?.customer.research?.doneDate).format("DD.MM.YYYY") : "-"} />
                            </table>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: "100%", overflow: "auto" }}>
                            <Typography variant='body2' fontWeight={600} sx={{ mb: 1 }}>
                                Kundenhistorie
                            </Typography>
                            <CustomerHistoryTable loadedData={task?.customerProjectPoolHistories} />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Fab onClick={() => window.open("tel:" + task?.customer.tel1)} color="secondary" sx={{ position: "absolute", bottom: 50, right: 40, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                <Call />
            </Fab>
        </Box>
    );
}