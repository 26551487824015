import { cloneElement } from "react";
import { menuItems } from "../components/AppMenu";

const findMenuItem = (items, menuKey) => {
    for (let i = 0; i < items.length; i++) {
        const menuItem = items[i].items ? findMenuItem(items[i].items, menuKey) : items[i].key === menuKey ? items[i] : null;
        if (!!menuItem) {
            return menuItem;
        }
    }
}

export const RenderMenuItem = ({ menuKey, menuProps }) => {
    if (!findMenuItem(menuItems, menuKey).component)
        return null
    else
        return cloneElement(findMenuItem(menuItems, menuKey).component, menuProps);
}

export const getMenuTitle = (menuKey) => {
    return findMenuItem(menuItems, menuKey).title;
}