import { Grid, Paper, Stack, TablePagination, Typography } from "@mui/material";
import { DATA_MODAL_COMPONENTS } from "../../../components/dataModal/DataModal";
import { withTableHelper } from "../../../components/table/utils";
import { TableToolbar } from "../../../components/table/TableToolbar";
import { DefaultTable } from "../../../components/table/DefaultTable";

const columnDef = [
    { id: 'salutation', label: 'Anrede', align: 'center', comp: DATA_MODAL_COMPONENTS.SELECT, selectValues: [{ value: "Herr", label: "Herr" }, { value: "Frau", label: "Frau" }] },
    { id: 'title', label: 'Titel', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'firstname', label: 'Vorname', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'lastname', label: 'Nachname', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'position', label: 'Position', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'tel', label: 'Telefon', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'email', label: 'E-Mail', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
];

const _ContactTable = (props) => {
    const { filter, onFilterChange, onChangePage, onChangeRowsPerPage, data, page, rowsPerPage, refreshData } = props;

    const dataForId = (id) => {
        return data.find(d => d.id === id);
    }

    return (
        <>
            <DefaultTable
                {...props}
                columnDef={columnDef}
                idKey={"id"}
                withSelection={false}
            />
        </>
    );
}

export const ContactTable = withTableHelper(_ContactTable, { withDefaultWrapper: false })