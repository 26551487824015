import { fetcher } from ".";

export const getTask = ({ employeeId, skip = false, projectId, resubmissionId }) => {
    return fetcher("/api/task?" + new URLSearchParams({ employeeId, skip, projectId, resubmissionId }), {
        method: "GET",
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const getHasActiveTask = (employeeId) => {
    return fetcher("/api/hasActiveTask?" + new URLSearchParams({ employeeId }), {
        method: "GET",
        credentials: "include",
        headers: new Headers()
    }).then((res) => res.json());
}

export const setResubmission = ({ date, employeeId, customerProjectPoolId }) => {
    return fetcher("/api/resubmission", {
        method: "POST",
        body: JSON.stringify({ date, employeeId, customerProjectPoolId }),
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const finishTask = (data) => {
    return fetcher("/api/finishTask", {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const cancelTask = (id) => {
    return fetcher("/api/cancelTask", {
        method: "POST",
        body: JSON.stringify({ id }),
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const getResearch = () => {
    return fetcher("/api/research", {
        method: "GET",
        credentials: "include",
        headers: new Headers()
    }).then((res) => res.json());
}

export const finishResearch = ({ customer, employeeId, researchId }) => {
    return fetcher("/api/finishResearch", {
        method: "POST",
        body: JSON.stringify({ customer, employeeId, researchId }),
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const customerDataModified = ({ customer, employeeId }) => {
    return fetcher("/api/customerDataModified", {
        method: "POST",
        body: JSON.stringify({ customer, employeeId }),
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}