

import { TableToolbar } from "../components/table/TableToolbar";
import { Box, Card, LinearProgress, Stack, Table, TableBody, TableContainer, TablePagination } from "@mui/material"
import { applyFilter, withTableHelper } from "../components/table/utils";
import { getCustomers, getEmployees, getProjects, setCustomer } from "../api";
import { DATA_MODAL_COMPONENTS, DATA_MODAL_STATES } from "../components/dataModal/DataModal";
import { DefaultTable } from "../components/table/DefaultTable";
import { useSnackbar } from "notistack";

const columnDef = [
    { id: 'selection', label: '', align: 'center' },
    { id: 'customerId', disabled: ({ config }) => config.modalState === DATA_MODAL_STATES.EDIT, label: 'Kundennummer', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'name1', label: 'Name 1', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'name2', required: false, label: 'Name 2', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'salutation', required: false, label: 'Anrede', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'contactPerson', required: false, label: 'Ansprechpartner', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'contactPersonPosition', required: false, label: 'Ansprechpartner (Position)', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'zipCode', label: 'PLZ', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'city', label: 'Stadt', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'address', label: 'Adresse', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'country', label: 'Land', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'tel1', label: 'Telefon 1', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'tel2', required: false, label: 'Telefon 2', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'mobile', required: false, label: 'Mobil', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'email', required: false, label: 'E-Mail', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    // {
    //     id: 'customerProjectPools', required: false, label: 'Letzer Abschluss', align: 'center', cellValueFormatter: (item) => {
    //         console.log({ item });
    //         if (item.length === 0) return "";
    //         const latest = item.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).shift();
    //         return latest.customerProjectPoolHistories[0].projectDealType.
    //         return ""
    //     }
    // },
    // {
    //     id: 'customerProjectPools', required: false, label: 'Letzer Abschluss (MA)', align: 'center', cellValueFormatter: (item) => {
    //         return ""
    //     }
    // },
    { id: 'note', required: false, multiline: true, label: 'Notizen', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'lockedUntilDaten', required: false, label: 'Gesperrt bis', align: 'center', comp: DATA_MODAL_COMPONENTS.DATE },
    { id: 'onBlacklist', required: false, type: "checkbox", label: 'Dauerhaft gesperrt', align: 'center', comp: DATA_MODAL_COMPONENTS.CHECKBOX },
];

export const _Customer = (props) => {
    const { filter, onFilterChange, onChangePage, onChangeRowsPerPage, data, page, rowsPerPage, refreshData } = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const onSave = ({ fieldValues, config }) => {
        return setCustomer(fieldValues)
            .then(() => {
                return refreshData();
            })
            .catch((err) => {
                enqueueSnackbar("Beim Speichern des Kunden ist ein Fehler aufgetreten.", { variant: "error" });
                return Promise.reject();
            });
    }

    const dataForId = (id) => {
        return data.find(d => d.customerId === id);
    }

    return (
        <>
            <TableToolbar
                {...props}
                filterName={filter}
                onFilterName={onFilterChange}
                placeholder={"Kunden suchen..."}
                columnDef={columnDef}
                context={"Kunde"}
                onSave={onSave}
                getDataForId={dataForId}
            />
            <DefaultTable
                columnDef={columnDef}
                idKey={"customerId"}
                singleSelection
                {...props}
            />
            <TablePagination
                page={page}
                sx={{ minHeight: 50, maxHeight: 50 }}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                onPageChange={onChangePage}
                rowsPerPageOptions={[20, 30, 50]}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </>
    );
}


export const Customer = withTableHelper(_Customer, { loadData: getCustomers })