import { AttachFile, CloudUpload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { useRef, useState } from "react"
import { styled } from "styled-components";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const FileUpload = ({ caption, onSelect, onSubmit, accept = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" }) => {

    const fileRef = useRef();

    const [file, setFile] = useState(null);
    const [inSubmit, setInSubmit] = useState(false);

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                {caption && <Typography variant="h5" sx={{ mb: 2 }}>{caption}</Typography>}
                <Button size="small" component="label" variant="contained" startIcon={<AttachFile />}>
                    Datei auswählen
                    <VisuallyHiddenInput onChange={(e) => {
                        if (onSelect) {
                            onSelect(e.target.files[0]);
                            fileRef.current.value = "";
                        }
                        else setFile(e.target.files[0]);
                    }} type="file" ref={fileRef} id="file" accept={accept} />
                </Button>
                {!!onSubmit && (
                    <div className="ml-5">
                        <LoadingButton
                            size="small"
                            onClick={() => {
                                setInSubmit(true);
                                onSubmit(file)
                                    .then(() => {
                                        setFile(null)
                                        fileRef.current.value = "";
                                    })
                                    .finally(() => setInSubmit(false));
                            }}
                            endIcon={<CloudUpload />}
                            loading={inSubmit}
                            loadingPosition="end"
                            variant="outlined"
                            disabled={!file}
                            sx={{ mt: 2 }}
                        >
                            Importieren
                        </LoadingButton>
                    </div>
                )}
            </div>
            {!!file && (
                <div className="mt-5" style={{ fontWeight: "bold" }}>
                    {file.name}
                </div>
            )}
        </>
    )
}