import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

export const TableNoData = ({ filter, noDataCaption = "Keine Daten vorhanden" }) => {
    return (
        <TableRow>
            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                <Paper
                    sx={{
                        textAlign: 'center',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexGrow: 1,
                        p: 1
                    }}
                >
                    <Typography variant="body1">
                        {noDataCaption}
                    </Typography>
                    {(String(filter).length > 0) && (
                        <Typography variant="body2">
                            Es konnten keine Daten für &nbsp;
                            <strong>&quot;{filter}&quot;</strong> gefunden werden.
                            <br /> Bitte eine andere Zeichenkette probieren.
                        </Typography>
                    )}
                </Paper>
            </TableCell>
        </TableRow>
    );
}

