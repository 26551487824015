import { TableToolbar } from "../components/table/TableToolbar";
import { Box, Card, LinearProgress, Stack, Table, TableBody, TableContainer, TablePagination } from "@mui/material"
import { applyFilter, withTableHelper } from "../components/table/utils";
import { getProjects, setProject, uploadFile } from "../api";
import { DATA_MODAL_COMPONENTS, DATA_MODAL_STATES } from "../components/dataModal/DataModal";
import { DefaultTable } from "../components/table/DefaultTable";
import { ProjectPool } from "./components/project/ProjectPool";
import { ProjectOwner } from "./components/project/ProjectOwner";
import { ProjectDealtType } from "./components/project/ProjectDealType";

const columnDef = [
    { id: 'selection', label: '', align: 'center' },
    { id: 'id', disabled: true, label: 'ID', align: 'center' },
    { id: 'projectName', label: 'Projektname', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'customer.name1', required: false, fieldId: "customer", label: 'Kunde Name 1', align: 'center', comp: ProjectOwner },
    { id: 'customer.name2', label: 'Kunde Name 2', align: 'center' },
    { id: 'description', label: 'Beschreibung', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'smtpServer', label: 'SMTP Server', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'smtpUsername', label: 'SMTP Benutzername', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'smtpPassword', secure: true, label: 'SMTP Passwort', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'smtpPort', label: 'SMTP Port', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'active', label: 'Aktiv', type: "checkbox", required: false, align: 'center', comp: DATA_MODAL_COMPONENTS.CHECKBOX },
    {
        id: 'projectPools', defaultValue: [], required: true, label: 'Pools', type: "nested", align: 'center', comp: ProjectPool, nestedColumnDef: [
            { id: 'poolName', label: 'Name', align: 'center' },
            { id: 'active', type: "checkbox", label: 'Aktiv', align: 'center' },
            { id: 'sumCustomer', label: 'Anzahl Kunden', align: 'center' },
        ]
    },
    {
        id: 'projectDealTypes', defaultValue: [], required: true, label: 'Abschlussarten', comp: ProjectDealtType, type: "nested", align: 'center', nestedColumnDef: [
            { id: 'dealType.dealType', label: 'Abschlusstyp', align: 'center' },
            { id: 'description', label: 'Beschreibung', align: 'center' },
            { id: 'emailSubject', label: 'E-Mail-Betreff', align: 'center' },
            { id: 'emailTemplate', label: 'E-Mail-Template', align: 'center' },
            { id: 'note', label: 'Notizen', align: 'center' }
        ]
    },
];

export const _Project = (props) => {
    const { filter, onFilterChange, onChangePage, onChangeRowsPerPage, data, page, rowsPerPage, refreshData } = props;

    const onSave = async ({ fieldValues }) => {
        return new Promise(async (res, rej) => {
            try {
                const preparedFieldValues = {
                    ...fieldValues,
                    customerId: fieldValues.customer.customerId,
                    projectPools: fieldValues.projectPools.map(p => ({ ...p, fileName: p.poolFile?.name }))
                }

                for (const pool of preparedFieldValues.projectPools) {
                    if (pool.poolFile) {
                        await uploadFile(pool.poolFile);
                    }
                }

                await setProject(preparedFieldValues);
                await refreshData();

                res();
            } catch (err) {
                console.log({ err });
                rej(err);
            }
        });
    }

    const dataForId = (id) => {
        return data.find(d => d.id === id);
    }

    return (
        <>
            <TableToolbar
                {...props}
                filterName={filter}
                onFilterName={onFilterChange}
                placeholder={"Projekt suchen..."}
                columnDef={columnDef}
                context={"Projekt"}
                onSave={onSave}
                getDataForId={dataForId}
            />
            <DefaultTable
                columnDef={columnDef}
                idKey={"id"}
                singleSelection
                {...props}
            />
            <TablePagination
                page={page}
                sx={{ minHeight: 50, maxHeight: 50 }}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                onPageChange={onChangePage}
                rowsPerPageOptions={[20, 30, 50]}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </>
    );
}


export const Project = withTableHelper(_Project, { loadData: getProjects })