import { store } from "../../reducers/store";
import { CircularProgress, Modal } from "@mui/material";

export const withWait = (cb, message = "") => {
    const state = store.getState();

    store.setState(
        {
            ...state,
            appState: {
                ...state.appState,
                loading: {
                    active: true,
                    message
                }
            }
        }
    );

    return cb().finally(() => {
        const state = store.getState();

        store.setState({
            ...state,
            appState: {
                ...state.appState,
                loading: {
                    active: false,
                    message: ""
                }
            }
        });
    });
}

export const withWaitModal = (cb) => {
    const state = store.getState();

    store.setState(
        {
            ...state,
            appState: {
                ...state.appState,
                showWait: true
            }
        }
    );

    return cb().finally(() => {
        const state = store.getState();

        store.setState({
            ...state,
            appState: {
                ...state.appState,
                showWait: false
            }
        });
    });
}

export const Wait = () => {
    const isVisible = store(state => state.appState.showWait);

    return (
        <Modal
            open={isVisible}
            disableEnforceFocus
            disableAutoFocus
        >
            <div style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100px",
                height: "100px",
                background: "white",
                borderRadius: 10
            }}>
                <div style={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </div>
            </div>
        </Modal>
    )
}