import { Stack, Typography } from "@mui/material"

export const TextItem = ({ caption, value = "", textAlign = "left" }) => {
    return (
        <tr>
            <td style={{ verticalAlign: "top" }}>
                <Typography variant="body2">
                    {caption}
                </Typography>
            </td>
            <td style={{ verticalAlign: "top", textAlign }}>
                {Array.isArray(value) ? (
                    <Stack direction={"column"} flexGrow={1} columnGap={1}>
                        {value.map(v => {
                            return (
                                <Typography variant="body2" fontWeight={600}>
                                    {v}
                                </Typography>
                            )
                        })}
                    </Stack>
                ) : (
                    <Typography variant="body2" fontWeight={600}>
                        {value}
                    </Typography>
                )}
            </td>
        </tr>
    )
}