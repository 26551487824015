import { Box, Button, IconButton, Modal, Stack, TextField, Typography } from "@mui/material"
import { isValidElement, useEffect, useState } from "react"
import { Iconify } from "../iconify";
import { Send } from "@mui/icons-material";
import LoadingButton from '@mui/lab/LoadingButton';
import { defaultModalStyle } from "../dataModal/DataModal";

export const CustomModal = ({ ModalContent, open, onClose }) => {
    if (!ModalContent) return null;
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box spacing={2} sx={defaultModalStyle}>
                {isValidElement(ModalContent) ? ModalContent : <ModalContent />}
            </Box>
        </Modal>
    )
}

const defaultModalOptions = {
    onSubmit: null,
    caption: "Nachricht",
    buttonCaption: "Senden",
    placeholder: "Nachricht eingeben...",
    submitSuccess: "Nachricht wurde erfolgreich versendet.",
    submitError: "Beim versenden der Nachricht ist ein Fehler aufgetreten."
}

export const withInputModal = (HoC) => (props) => {

    const [open, setOpen] = useState(false);
    const [modalOptions, setModalOptions] = useState(defaultModalOptions)
    const [inSubmit, setInSubmit] = useState(false);

    useEffect(() => {
        if (!open) {
            setInSubmit(false);
            setModalOptions(defaultModalOptions);
        }
    }, [open]);

    const ModalContent = () => {
        const [text, setText] = useState("")

        return (
            <>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="h6">{modalOptions.caption}</Typography>
                    <IconButton onClick={() => setOpen(false)}>
                        <Iconify icon="material-symbols:close" />
                    </IconButton>
                </Stack>
                <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder={modalOptions.placeholder}
                    multiline
                />
                <LoadingButton
                    onClick={() => {
                        setInSubmit(true)
                        modalOptions.onSubmit(text)
                            .then(() => {
                                setOpen(false);
                                alert(modalOptions.submitSuccess);
                            })
                            .catch((err) => {
                                setInSubmit(false);
                                alert(modalOptions.submitError)
                            })
                    }}
                    endIcon={<Send />}
                    loading={inSubmit}
                    loadingPosition="end"
                    variant="outlined"
                    sx={{ mt: 2 }}
                >
                    {modalOptions.buttonCaption}
                </LoadingButton>
            </>
        )
    }

    const showModal = ({ onSubmit, caption, placeholder, buttonCaption, submitError, submitSuccess } = defaultModalOptions) => {
        setModalOptions({ onSubmit, caption, placeholder, buttonCaption, submitError, submitSuccess });
        setOpen(true);
    }

    return (
        <>
            <CustomModal ModalContent={ModalContent} open={open} onClose={() => setOpen(false)} />
            <HoC {...props} showModal={showModal} />
        </>
    )
}

export const withCustomModal = (HoC) => (props) => {
    const [open, setOpen] = useState(false);
    const [ModalContent, setModalContent] = useState(null)

    useEffect(() => {
        if (!open) setModalContent(null)
    }, [open]);

    const showModal = (ModalContent, props) => {
        setModalContent(ModalContent);
        setOpen(true);
    };
    const closeModal = () => setOpen(false);

    return (
        <>
            <CustomModal ModalContent={ModalContent} open={open} onClose={closeModal} />
            <HoC {...props} showModal={showModal} closeModal={closeModal} />
        </>
    )
}