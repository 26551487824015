import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, CssBaseline, Toolbar, AppBar as MuiAppBar, IconButton, Typography, Badge, Drawer as MuiDrawer, Divider, List, Container, Grid, Paper, Link, Button, Card, Stack, Avatar } from '@mui/material';
import { store } from '../reducers/store';
import { RenderMenuItem, getMenuTitle } from '.';
import { AppMenu } from '../components/AppMenu';
import { Iconify } from '../components/iconify';
import { DATA_MODAL_COMPONENTS } from '../components/dataModal/DataModal';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import { finishTimeRecord, getProjects, getTimeTrackingTypes, setTimeRecord } from '../api';
import { useSnackbar } from 'notistack';
import { withWaitModal } from '../components/wait';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {/* {'Copyright © '}
            <Link color="inherit" href="mailto:mark@friedlaender.berlin">
                Mark Friedländer
            </Link>{' '}
            {new Date().getFullYear()}
            {' | '} */}
            {' Version 1.2.8'}
        </Typography>
    );
}

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: 0,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: 0,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: 0,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: 0,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default function Home() {

    const setAppState = store((state) => state.appState.setAppState);
    const menuItemKey = store((state) => state.appState.menuItemKey);
    const menuItemProps = store((state) => state.appState.menuItemProps);
    const menuCollapsed = store((state) => state.appState.menuCollapsed);
    const user = store((state) => state.appState.user);
    const showDataModal = store((state) => state.appState.showDataModal);
    const timeTrackingStart = store((state) => state.appState.timeTrackingStart);
    const [timeTrackingDiff, setTimeTrackingDiff] = useState(0);
    const [timeTrackingTypes, setTimeTrackingTypes] = useState();
    const [projects, setProjects] = useState();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    let timeTrackingInterval;

    useEffect(() => {
        Promise.all([getTimeTrackingTypes(), getProjects()])
            .then(data => {
                setTimeTrackingTypes(data[0]);
                setProjects(data[1]);
            });
    }, []);

    const updateTimeTracking = () => {
        const diff = moment().diff(timeTrackingStart, "seconds");
        setTimeTrackingDiff(diff);
    }

    useEffect(() => {
        if (timeTrackingStart && !timeTrackingInterval) {
            timeTrackingInterval = setInterval(updateTimeTracking, 1000);
        } else if (!timeTrackingStart && timeTrackingInterval) {
            clearInterval(timeTrackingInterval);
        }
    }, [timeTrackingStart]);

    const onTimeTrackClick = () => {
        if (timeTrackingStart) {
            if (window.confirm("Möchten Sie die laufende Zeiterfassung beenden und speichern?")) {
                withWaitModal(() => {
                    return finishTimeRecord(user.id)
                        .then(() => {
                            setAppState("timeTrackingRefresh", moment());
                            setAppState("timeTrackingStart", null);
                        })
                        .catch(() => enqueueSnackbar("Beim Speichern der Zeiterfassung ist ein Fehler aufgetreten.", { variant: "error" }));
                })
            }
        } else {
            showDataModal({
                caption: "Zeiterfassung", fields: [
                    { id: 'timeTrackingTypeId', label: "Zeiterfassung für", align: "center", comp: DATA_MODAL_COMPONENTS.SELECT, selectValues: timeTrackingTypes ? timeTrackingTypes.map((v) => ({ label: v.label, value: v.id })) : [] },
                    { id: 'projectId', required: ({ depValue }) => !!depValue && depValue === "anwesend", dependency: "timeTrackingTypeId", visible: ({ value, depValue }) => !!depValue && depValue === "anwesend", label: 'Projekt', align: 'center', comp: DATA_MODAL_COMPONENTS.SELECT, selectValues: projects ? projects.map(p => ({ label: p.projectName, value: p.id })) : [] },
                    { id: 'startTime', dependency: "timeTrackingTypeId", label: 'Start', align: 'center', onlyDate: ({ depValue }) => !!depValue && depValue !== "anwesend", comp: DATA_MODAL_COMPONENTS.DATE_TIME, defaultValue: moment() },
                    { id: 'endTime', dependency: "timeTrackingTypeId", required: ({ depValue }) => !!depValue && depValue !== "anwesend", onlyDate: ({ depValue }) => !!depValue && depValue !== "anwesend", required: false, label: 'Ende', align: 'center', comp: DATA_MODAL_COMPONENTS.DATE_TIME },
                    { id: 'halfDay', dependency: "timeTrackingTypeId", visible: ({ value, depValue }) => !!depValue && depValue !== "anwesend", required: false, label: 'Halber Tag', align: 'center', comp: DATA_MODAL_COMPONENTS.CHECKBOX }
                ], onSave: ({ fieldValues }) => {
                    return setTimeRecord({
                        ...fieldValues,
                        employeeId: user.id
                    })
                        .then(() => {
                            if (!fieldValues.endTime) {
                                setAppState("timeTrackingStart", fieldValues.startTime);
                            }
                            setAppState("timeTrackingRefresh", moment());
                        })
                        .catch((err) => {
                            enqueueSnackbar("Beim Speichern der Zeiterfassung ist ein Fehler aufgetreten.", { variant: "error" })
                            return Promise.reject();
                        });
                }, modalId: "time_tracking"
            });
        }
    }

    let hours = Math.floor(timeTrackingDiff / 3600);
    hours = hours.toString().length < 2 ? "0" + hours : hours;
    let minutes = Math.floor((timeTrackingDiff % 3600) / 60);
    minutes = minutes.toString().length < 2 ? "0" + minutes : minutes;
    let seconds = (timeTrackingDiff % 3600) % 60;
    seconds = seconds.toString().length < 2 ? "0" + seconds : seconds;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" color='transparent' sx={{ boxShadow: "none", ml: 0, left: menuCollapsed ? 75 : 250, width: menuCollapsed ? "calc(100% - 75px)" : "calc(100% - 250px)" }} open={!menuCollapsed} style={{ background: "white", zIndex: 0, borderBottom: "1px solid #e9e9e9" }}>
                <Toolbar >
                    <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, fontWeight: "600" }}
                    >
                        {getMenuTitle(menuItemKey)}
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} spacing={2}>
                        <Box sx={{ border: "1px solid #e9e9e9" }}>
                            <IconButton
                                onClick={onTimeTrackClick}
                                // disabled={!timeTrackingTypes}
                                disabled={true}
                                disableElevation
                                disableRipple
                            >
                                <Iconify icon={timeTrackingStart ? "mdi:pause" : "mdi:play"} color={timeTrackingStart ? "red" : "green"} />
                                <Typography sx={{ ml: 1 }} variant='body2'>{`${hours}:${minutes}:${seconds}`}</Typography>
                            </IconButton>
                        </Box>
                        <Avatar src={user.url ? user.url[1] : null} />
                        <Stack direction={"column"}>
                            <Typography
                                variant="body2"
                            >
                                {user.firstname}
                            </Typography>
                            <Typography
                                variant="body2"
                            >
                                {user.lastname}
                            </Typography>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Drawer transitionDuration={0} variant="permanent" open={!menuCollapsed} PaperProps={{ style: { width: menuCollapsed ? "75px" : "250px", border: 0 } }}>
                <AppMenu />
            </Drawer>
            <Box
                height={"100vh"}
                width={"100vw"}
                display={"flex"}
                flexDirection={"column"}
                overflow={"hidden"}
                sx={{ backgroundColor: "rgb(246, 248, 251)", pl: 3, pr: 3, pt: 2, pb: 2 }}
            >
                <Box minHeight={64} width={"100%"} />
                <RenderMenuItem menuKey={menuItemKey} menuProps={menuItemProps} />
                <Copyright sx={{ pt: 2, fontSize: 12 }} />
            </Box>
        </Box>
    );
}