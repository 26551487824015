import { store } from "../reducers/store";

const baseUrl = process.env.REACT_APP_SERVER_BASEURL || "";

export const fetcher = (url, params) => {
    return new Promise((res, rej) => {
        fetch(baseUrl + url, params)
            .then((response) => {
                console.log({ response })
                if (!response.ok) {
                    if (response.status === 403) {
                        store.getState().appState.setAppState("isLoggedIn", false);
                    }

                    rej(response)
                } else {
                    res(response)
                }
            })
            .catch((err) => rej(err));
    });
};

export const getSession = () => {
    return fetcher('/api/session', {
        method: 'GET',
        credentials: 'include',
    })
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject()
            }
            return response.json();
        })
        .then((data) => {
            console.log({ data })
            return Promise.resolve(data);
        });
}

export const login = ({ username, password, loginCode, with2FA }) => {
    return fetcher("/api/session", {
        method: "POST",
        body: JSON.stringify({ username, password, loginCode, with2FA }),
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const generateLoginCode = ({ username }) => {
    return fetcher("/api/generateLoginCode", {
        method: "POST",
        body: JSON.stringify({ username }),
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const logout = () => {
    return fetcher("/api/session", {
        method: "DELETE",
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const getBaseData = () => {
    return fetcher("/api/baseData", {
        method: "GET",
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const getProjectDealTypes = (projectId) => {
    return fetcher("/api/projectDealTypes?" + new URLSearchParams({ projectId }), {
        method: "GET",
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const getDealTypes = () => {
    return fetcher("/api/dealTypes", {
        method: "GET",
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const getProjects = () => {
    return fetcher("/api/projects", {
        method: "GET",
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const getEmployees = () => {
    return fetcher("/api/employees", {
        method: "GET",
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const deleteEmployees = (employeeIds) => {
    return fetcher("/api/employees", {
        method: "DELETE",
        body: JSON.stringify(employeeIds),
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const setEmployee = (employee) => {
    return fetcher("/api/employee", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(employee),
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const getStates = () => {
    return fetcher("/api/states", {
        method: "GET",
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const setState = (state) => {
    return fetcher("/api/state", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(state),
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const getFirstContacts = () => {
    return fetcher("/api/firstContacts", {
        method: "GET",
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const setFirstContact = (firstContact) => {
    return fetcher("/api/firstContact", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(firstContact),
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return fetcher("/api/file", {
        method: "POST",
        body: formData,
        credentials: 'include',
        headers: new Headers(),
    });
}

export const setProject = (projectData) => {
    return fetcher("/api/project", {
        method: "POST",
        body: JSON.stringify(projectData),
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const getCustomers = (customerSearch) => {
    const url = customerSearch ? "/api/customers?" + new URLSearchParams({ customerSearch }) : "/api/customers";
    return fetcher(url, {
        method: "GET",
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const getResubmissions = (employeeId) => {
    const url = employeeId ? "/api/resubmissions?" + new URLSearchParams({ employeeId }) : "/api/resubmissions";
    return fetcher(url, {
        method: "GET",
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const finishResubmission = (task) => {
    return fetcher("/api/finishResubmission", {
        method: "POST",
        body: JSON.stringify(task),
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const getObjections = () => {
    return fetcher("/api/objections", {
        method: "GET",
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const getGuidelines = () => {
    return fetcher("/api/guidelines", {
        method: "GET",
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const setCustomer = (customer) => {
    return fetcher("/api/customer", {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify(customer),
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const getProjectDealTypeStats = ({ projectId, startDate, endDate, employeeId }) => {
    const params = {}

    if (endDate) params.endDate = endDate;
    if (startDate) params.startDate = startDate;
    if (projectId) params.projectId = projectId;
    if (employeeId) params.employeeId = employeeId;

    return fetcher("/api/projectDealTypeStats?" + new URLSearchParams(params), {
        method: "GET",
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const getProjectStats = ({ projectId }) => {
    return fetcher("/api/projectStats?" + new URLSearchParams({ projectId }), {
        method: "GET",
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' })
    }).then((res) => res.json());
}

export const getStatsExcel = ({ projectId, startDate, endDate }) => {
    const params = endDate ? { projectId, startDate, endDate } : { projectId, startDate };
    return fetcher("/api/statsExcel?" + new URLSearchParams(params), {
        method: "GET",
        credentials: 'include',
        headers: new Headers({ 'Content-Type': 'application/json' }),
    }).then(res => res.blob());
}

export * from "./task";
export * from "./timeTracking";