import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { Checkbox, IconButton, Stack, Table, TableBody } from '@mui/material';
import { ArrowDownward, ArrowDropDown, ArrowRight } from '@mui/icons-material';
import React, { isValidElement, useState } from 'react';
import { CustomTableHead } from './DefaultTableHead';
import { store } from '../../reducers/store';


export const DefaultTableRow = ({ row, singleSelection, tableId, columnDef, withSelection = true, id, extraCellProps }) => {
    const selectedTableCells = store((state) => state.appState.selectedTableCells[tableId]);
    const toggleCell = store((state) => state.appState.toggleCell);
    const toggleCellSingle = store((state) => state.appState.toggleCellSingle);

    const [showNested, setShowNested] = useState(false);

    const CellItem = ({ type, secure, cellValueFormatter, cellRenderer, item, unit = "", id, nestedColumnDef, nestedId }) => {
        if (type === "checkbox") {
            return (
                <TableCell sx={{ verticalAlign: showNested ? "top" : "middle" }} key={id} align="center">
                    <Typography variant="body2">
                        <Checkbox sx={{ p: showNested ? 1 : 0 }} color='primary' checked={item} disabled />
                    </Typography>
                </TableCell>
            )
        } else if (type === "nested") {
            return (
                <TableCell sx={{ verticalAlign: showNested ? "top" : "middle" }} key={id} align="center">
                    <IconButton onClick={() => setShowNested(!showNested)}>
                        <Stack direction={"row"} alignItems={"center"} rowGap={2}>
                            <Typography variant='body2'>{item.length}</Typography>
                            {showNested ? <ArrowDropDown /> : <ArrowRight />}
                        </Stack>
                    </IconButton>
                    {showNested && (
                        <Table>
                            <CustomTableHead
                                rowCount={item.length}
                                headLabel={nestedColumnDef}
                            />
                            <TableBody>
                                {item.map(row => {
                                    return (
                                        <DefaultTableRow withSelection={false} key={row[nestedId]} row={row} columnDef={nestedColumnDef} />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    )}
                </TableCell>
            )
        }

        if (cellRenderer) {
            return (
                <TableCell sx={{ verticalAlign: showNested ? "top" : "middle" }} key={id} align="center">
                    {cellRenderer({ row, extraCellProps })}
                </TableCell>
            )
        }

        return (
            <TableCell sx={{ verticalAlign: showNested ? "top" : "middle" }} key={id} align="center">
                <Typography variant='body2' sx={{ p: showNested ? 1 : 0 }}>
                    {cellValueFormatter ? cellValueFormatter(item) : secure ? "*****" : item} {unit}
                </Typography>
            </TableCell>
        )
    }

    const _toggleCell = () => {
        if (singleSelection) {
            toggleCellSingle(tableId, id);
        } else {
            toggleCell(tableId, id);
        }
    }

    return (
        <>
            <TableRow hover tabIndex={-1}>
                {withSelection && (
                    <TableCell sx={{ verticalAlign: showNested ? "top" : "middle" }} align='center' onClick={_toggleCell}>
                        <Checkbox
                            color="primary"
                            sx={{ p: showNested ? 1 : 0 }}
                            checked={!!selectedTableCells.find(c => c === id)}
                        />
                    </TableCell>
                )}
                {columnDef.map((c) => {
                    if (!c.label || String(c.label).length === 0) return;

                    let item;

                    if (String(c.id).includes(".")) {
                        const subAttr = String(c.id).split(".");
                        item = row;

                        subAttr.forEach(attr => {
                            if (item) {
                                item = item[attr]
                            }
                        });
                    } else {
                        item = row[c.id]
                    }

                    if (c.hasOwnProperty("inTable") && c.inTable === false) return null

                    return (
                        <CellItem {...c} item={item} />
                    )
                })}
            </TableRow>
        </>
    );
}