import { Box, Checkbox, IconButton, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { DATA_MODAL_COMPONENTS, DATA_MODAL_STATES } from "../../../components/dataModal/DataModal";
import { FileUpload } from "../../../components/fileUpload";
import { Iconify } from "../../../components/iconify";
import { DefaultTable } from "../../../components/table/DefaultTable";
import { TableToolbar } from "../../../components/table/TableToolbar";
import { withTableHelper } from "../../../components/table/utils";
import { store } from "../../../reducers/store";

const AddProjectPool = ({ modalId, id, disabled, updateFieldValue, config }) => {

    if (config.modalState === DATA_MODAL_STATES.EDIT) return null;

    const file = store((state) => state.appState.fieldValues[modalId][id]);

    if (!!file) {
        return (
            <Stack direction={"row"} alignItems={"center"} columnGap={3}>
                <Typography variant="body1">{file.name}</Typography>
                <IconButton onClick={() => updateFieldValue(id, null)}>
                    <Iconify icon="material-symbols:close" />
                </IconButton>
            </Stack>
        )
    }
    return (
        <FileUpload onSelect={(file) => updateFieldValue(id, file)} />
    )
}

const columnDef = [
    { id: 'selection', label: '', align: 'center' },
    { id: 'id', label: 'Pool-ID', align: 'center' },
    { id: 'poolName', label: 'Pool', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'active', required: false, label: 'Aktiv', type: "checkbox", align: 'center', comp: DATA_MODAL_COMPONENTS.CHECKBOX },
    { id: 'poolFile', required: ({ config }) => config.modalState === DATA_MODAL_STATES.NEW, label: 'Import-Datei', align: "center", inTable: false, comp: AddProjectPool }
];

const _ProjectPool = (props) => {
    const { filter, modalId, id, onFilterChange, onChangePage, onChangeRowsPerPage, page, rowsPerPage, refreshData, updateFieldValue } = props;

    const pools = store((state) => state.appState.fieldValues[modalId][id]);

    const dataForId = (id) => {
        return pools.find(d => d.id === id);
    }

    const onSave = ({ fieldValues }) => {
        return new Promise((res, rej) => {
            if (!!fieldValues.id) {
                updateFieldValue(id, pools.map(p => {
                    if (p.id === fieldValues.id) {
                        return {
                            ...fieldValues
                        }
                    }
                    return p;
                }));
            } else {
                const newPool = {
                    ...fieldValues
                }
                console.log({ pools })
                const minId = Math.min.apply(Math, pools.map(p => p.id));
                newPool.id = minId < 0 ? minId - 1 : -1;
                updateFieldValue(id, [...pools, newPool]);
            }

            res();
        });
    }

    return (
        <Paper sx={{ maxWidth: "100%", minWidth: "100%", maxHeight: 400, overflow: "auto", p: 2 }}>
            <Typography variant="subtitle1" fontWeight={600} sx={{ mb: 2 }}>Projekt-Pools</Typography>
            <TableToolbar
                {...props}
                filterName={filter}
                placeholder={"Pool suchen..."}
                columnDef={columnDef}
                context={"Pool"}
                onSave={onSave}
                getDataForId={dataForId}
                modalId={"modal_project_pool"}
            />
            <DefaultTable
                {...props}
                data={pools}
                columnDef={columnDef}
                idKey={"id"}
                noData={pools.length === 0}
                noDataCaption={"Keine Pools vorhanden..."}
                singleSelection
                showAll
            />
        </Paper>
    );
}

export const ProjectPool = withTableHelper(_ProjectPool, { tableId: "project_pools_table", withDefaultWrapper: false })