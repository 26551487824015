import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { store } from '../../reducers/store';
import { Checkbox, Container, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Select, Stack, TextField } from '@mui/material';
import { isFunction } from 'lodash';
import { Iconify } from '../iconify';
import { useEffect } from 'react';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { Save } from '@mui/icons-material';
import { DateTimePicker, DateTimePickerTabs } from '@mui/x-date-pickers';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

export const defaultModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "90%",
    minWidth: "40%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxHeight: "90%",
    borderRadius: 2,
    //   overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
};

export const DATA_MODAL_STATES = {
    "NEW": 0,
    "EDIT": 1
}

const DMTextField = ({ label, secure, multiline = false, visible = true, dependency, required = true, unit = "", disabled = false, updateFieldValue, id, modalId, config }) => {
    const value = store((state) => {
        let data = state.appState.fieldValues[modalId];

        if (String(id).includes(".")) {
            const idSplit = String(id).split(".");
            idSplit.forEach((_id) => {
                if (!!data && data[_id]) {
                    data = data[_id];
                } else {
                    data = "";
                }
            })
        } else {
            data = state.appState.fieldValues[modalId][id] || "";
        }
        return data;
    });

    const depValue = dependency ? store((state) => state.appState.fieldValues[modalId][dependency]) : null;

    if (typeof visible === "function" && !visible({ value, depValue })) {
        return null;
    } else if (!visible) {
        return null;
    }

    const isRequired = isFunction(required) ? required({ config, value }) : required;
    const isDisabled = isFunction(disabled) ? disabled({ config, value }) : disabled;

    return (
        <TextField type={secure ? "password" : "text"} multiline={multiline} InputProps={{ endAdornment: <InputAdornment position='start'>{unit}</InputAdornment> }} fullWidth value={value} required={isRequired} onChange={(e) => updateFieldValue(id, e.target.value)} label={label} disabled={isDisabled} />
    )
}

const DMSelect = ({ label, multiple = false, dependency, visible = true, required = true, selectValues, disabled = false, modalId, updateFieldValue, id, config }) => {
    const value = store((state) => state.appState.fieldValues[modalId][id]);
    const depValue = dependency ? store((state) => state.appState.fieldValues[modalId][dependency]) : null;

    if (typeof visible === "function" && !visible({ value, depValue })) {
        return null;
    } else if (!visible) {
        return null;
    }

    const isRequired = isFunction(required) ? required({ config, value, depValue }) : required;
    const isDisabled = isFunction(disabled) ? disabled({ config, value, depValue }) : disabled;

    return (
        <FormControl
            required={isRequired}
            disabled={isDisabled}
            fullWidth
        >
            <InputLabel>{label}</InputLabel>
            <Select
                multiple={multiple}
                value={value || ""}
                label={label}
                onChange={(e) => updateFieldValue(id, e.target.value)}
                renderValue={(selected) => {
                    console.log({ selected })
                    if (Array.isArray(selected)) {
                        return selectValues.filter(s => selected.includes(s.value)).map(s => s.label).join(", ");
                    } else {
                        return selectValues.filter(s => s.value === selected).map(s => s.label).join(", ");
                    }
                }}
            >
                {selectValues.map(s => {
                    return (
                        <MenuItem disabled={s.disabled} value={s.value}>
                            {multiple && <Checkbox checked={value?.indexOf(s.value) > -1} />}
                            {s.labelComp ? (
                                <>
                                    {s.labelComp}
                                </>
                            ) : (
                                <ListItemText>{s.label}</ListItemText>
                            )}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

const DMText = ({ caption }) => {
    return (
        <Typography variant='h6'>
            {caption}
        </Typography>
    )
}

const DMDate = ({ label, required = true, selectValues, disabled = false, modalId, updateFieldValue, id, config }) => {
    const value = store((state) => state.appState.fieldValues[modalId][id]);

    return (
        <DatePicker
            sx={{ width: "100%" }}
            label={label}
            value={value}
            onChange={(newValue) => updateFieldValue(id, newValue)}
        />
    )
}

const DMDateTime = (props) => {
    const { label, onlyDate, dependency, required = true, future = false, selectValues, disabled = false, modalId, updateFieldValue, id, config } = props;

    const value = store((state) => state.appState.fieldValues[modalId][id]);
    const depValue = dependency ? store((state) => state.appState.fieldValues[modalId][dependency]) : null;

    if (onlyDate && onlyDate({ depValue })) {
        return <DMDate {...props} />
    }

    return (
        <DateTimePicker
            sx={{ width: "100%" }}
            label={label}
            value={value}
            onChange={(newValue) => updateFieldValue(id, newValue)}
            disablePast={future}

        />
    )
}

const DMCheckbox = ({ label, required = true, dependency, visible = true, selectValues, disabled = false, modalId, updateFieldValue, id, config }) => {
    const value = !!store((state) => state.appState.fieldValues[modalId][id]);
    const depValue = dependency ? store((state) => state.appState.fieldValues[modalId][dependency]) : null;

    if (typeof visible === "function" && !visible({ value, depValue })) {
        return null;
    } else if (!visible) {
        return null;
    }
    return (
        <FormControlLabel
            control={
                <Checkbox
                    color='primary'
                    checked={value}
                    onChange={e => updateFieldValue(id, e.target.checked)}
                />
            }
            label={label}
        />
    )
}

const DMWYSIWYGEdit = ({ label, required = true, selectValues, disabled = false, modalId, updateFieldValue, id, config }) => {
    const value = store((state) => state.appState.fieldValues[modalId][id]);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        console.log({ value })
        if (value) {
            const contentBlock = htmlToDraft(value);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            }
        }
    }, []);

    const onEditorStateChange = (newEditorState) => {
        const draft = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));

        setEditorState(newEditorState);
        updateFieldValue(id, draft);
    }

    return (
        <Stack direction={"column"} spacing={2} sx={{ p: 2, border: "1px solid #e9e9e9" }}>
            <Typography variant='headline6'>{label}</Typography>
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
            />
        </Stack>
    )
}

export const DATA_MODAL_COMPONENTS = {
    "TEXT_FIELD": DMTextField,
    "CHECKBOX": DMCheckbox,
    "TEXT": DMText,
    "SELECT": DMSelect,
    "DATE": DMDate,
    "DATE_TIME": DMDateTime,
    "WYSIWYG_EDITOR": DMWYSIWYGEdit
}

export const DataModal = ({ modalId }) => {
    const hideDataModal = store((state) => state.appState.hideDataModal);
    const config = store((state) => state.appState.dataModalConfigs[modalId]);
    const initFieldValues = store((state) => state.appState.initFieldValues);
    const setFieldValue = store((state) => state.appState.setFieldValue);

    const { caption, fields, state, onSave, values } = config;

    const [isSaving, setIsSaving] = useState(false);

    console.log({ values })
    useEffect(() => {
        initFieldValues(modalId, createDefaultValues());
    }, [fields]);

    const createDefaultValues = () => {
        let defaultValues = {};

        if (!fields) {
            return defaultValues;
        } else if (values) {
            defaultValues = { ...values }
            fields.forEach(f => {
                const id = f.fieldId || f.id;
                if (values[id]) {
                    if (f.type === "date") {
                        defaultValues[id] = moment(values[id]);
                    } else {
                        defaultValues[id] = f.prepareValues ? f.prepareValues(values[id]) : values[id];
                        console.log({ vFor: defaultValues[id] });
                    }
                }
            });
        } else {
            fields.forEach(f => {
                if (f.defaultValue) {
                    const id = f.fieldId || f.id;
                    defaultValues[id] = f.defaultValue;
                }
            });
        }

        return defaultValues;
    }

    const updateFieldValue = (fieldKey, newVal) => {
        setFieldValue(modalId, fieldKey, newVal);
    }

    const handleClose = () => hideDataModal(modalId);

    const _onSave = () => {
        setIsSaving(true);
        let unvalidFields = [];
        let tranformedValues = values ? { ...values } : {};

        const fieldValues = store.getState().appState.fieldValues[modalId];

        fields.forEach((f) => {
            const id = f.fieldId || f.id;
            let value = fieldValues;

            if (String(id).includes(".")) {
                const idSplit = String(id).split(".");
                idSplit.forEach((_id) => {
                    if (!!value && value[_id]) {
                        value = value[_id];
                    } else {
                        value = null;
                    }
                });
            } else {
                value = fieldValues[id];
            }

            if ((!f.hasOwnProperty("required") || (typeof f.required === "function" && f.required({ value: fieldValues[f.id], depValue: f.dependency ? fieldValues[f.dependency] : null, config })) || (typeof f.required !== "function" && !!f.required))
                && (!value || String(value).length === 0)
                && f.comp
            ) {
                unvalidFields.push(f.label);
            } else {
                if (moment.isMoment(value)) {
                    tranformedValues[id] = value.format();
                } else {
                    tranformedValues[id] = value;
                }
            }
        });

        console.log({ unvalidFields })

        if (unvalidFields.length === 0) {
            onSave({ config, fieldValues: tranformedValues })
                .then(() => handleClose())
                .catch((err) => console.log({ err }))
                .finally(() => setIsSaving(false));
        } else {
            alert("Speichern aktuell nicht möglich. Folgende Pflichtfelder müssen befüllt werden: " + unvalidFields.join(", "));
            setIsSaving(false);
        }
    }

    return (
        <Modal
            open={true}
            onClose={handleClose}
        // slotProps={{backdrop:{style: {backgroundColor: "white", opacity: 0}}}}
        >
            <Box sx={defaultModalStyle}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ p: 2, borderBottom: "1px solid #e9e9e9" }}>
                    <Typography variant="h6" fontWeight={600}>
                        {caption}
                    </Typography>
                    <IconButton disabled={isSaving} onClick={handleClose}>
                        <Iconify icon="material-symbols:close" width={25} />
                    </IconButton>
                </Stack>
                <Box display={"flex"} flexDirection={"column"} overflow={"auto"} flexGrow={1} sx={{ p: 2 }}>
                    <Stack direction={"column"} flexGrow={1} alignItems={"flex-start"} spacing={2}>
                        {fields.map(f => {
                            if (!f.comp) return null;
                            return React.createElement(f.comp, { ...f, id: f.fieldId || f.id, modalId, updateFieldValue, config });
                        })}
                    </Stack>
                </Box>
                <Stack direction={"row"} columnGap={1} alignItems="center" justifyContent={"flex-end"} sx={{ p: 2, borderTop: "1px solid #e9e9e9" }}>
                    <LoadingButton
                        onClick={_onSave}
                        loading={isSaving}
                        color="secondary"
                        size='small'
                        variant="contained"
                    >
                        <span>Speichern</span>
                    </LoadingButton>
                    <Button variant='outlined' size='small' disabled={isSaving} onClick={handleClose}>Schließen</Button>
                </Stack>
            </Box>
        </Modal>
    );
}