import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import { Checkbox, Typography } from '@mui/material';

export const CustomTableHead = ({
    order,
    orderBy,
    rowCount,
    headLabel,
    numSelected,
    onSelectAllClick,
}) => {
    return (
        <TableHead>
            <TableRow>
                {headLabel.map((headCell) => {
                    if (headCell.hasOwnProperty("inTable") && headCell.inTable === false) return null
                    if (headCell.id === "selection") {
                        return (
                            <TableCell
                                key={headCell.id}
                                align={headCell.align || 'left'}
                                sx={{ width: headCell.width, minWidth: headCell.minWidth, whiteSpace: "nowrap" }}
                                padding="checkbox"
                            >
                                {/* <Checkbox color='primary'  /> */}
                            </TableCell>
                        )
                    }
                    return (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align || 'left'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            sx={{ width: headCell.width, minWidth: headCell.minWidth, whiteSpace: "nowrap" }}
                        >
                            <Typography variant='body2'>{headCell.label}</Typography>
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}