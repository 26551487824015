import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Avatar, Button, Box, Checkbox, Container, CssBaseline, FormControl, FormControlLabel, Grid, IconButton, Input, InputAdornment, InputLabel, Link, MenuItem, Select, TextField, ThemeProvider, Typography, createTheme, Card, CardContent } from '@mui/material';
import { useEffect, useState } from 'react';
import { store } from '../reducers/store';
import { withWait } from '../components/wait';
import { generateLoginCode, getBaseData, login } from '../api';
import Logo from "../logo.png"
import { useSnackbar } from "notistack";
import { LoadingButton } from '@mui/lab';
import moment from 'moment';

export default function Login() {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const setAppState = store((state) => state.appState.setAppState);

    const [showPassword, setShowPassword] = useState(false);
    const [show2FACode, setShow2FACode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(0);

    const onLogin = (e) => {
        e.preventDefault();

        setLoading(true);

        const data = new FormData(e.currentTarget);

        if (!data.get('username') || !data.get('password')) {
            enqueueSnackbar("Für die Anmeldung ist die Mitarbeiter-ID und das Passwort erforderlich!", { variant: "error" });
            setLoading(false);
            return;
        } else if (show2FACode && !data.get("code")) {
            enqueueSnackbar("Für die Anmeldung ist ein Bestätigungscode erforderlich!", { variant: "error" });
            setLoading(false);
            return;
        }

        login({
            username: data.get('username'),
            password: data.get('password'),
            loginCode: data.get('code'),
            with2FA: show2FACode
        })
            .then((res) => {
                if (res.status === 401) {
                    alert("Beim Login ist ein Fehler aufgetreten. Mögliche Ursachen sind eine falsche Kombination aus Benutzer-ID und Passwort.")
                } else {
                    if (!show2FACode) {
                        return generateLoginCode({ username: data.get('username') })
                            .then(() => {
                                setCounter(60);
                                setShow2FACode(true);
                            })
                            .catch(() => enqueueSnackbar("Beim Versenden des Bestätigungscodes ist ein Fehler aufgetreten.", { variant: "error" }))
                    }
                    return Promise.all([res.json(), getBaseData()]).then((data) => {
                        if (data[0].timeTrackings.length > 0) {
                            setAppState("timeTrackingStart", moment(data[0].timeTrackings[0].startTime));
                        }
                        setAppState("user", data[0]);
                        setAppState("baseData", data[1]);
                        setAppState("isLoggedIn", true);
                    });
                }
            })
            .catch((res) => {
                res.json().then(err => {
                    enqueueSnackbar(err.message, { variant: "error" });
                });
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    return (
        <div className='App-header'>
            <CssBaseline />
            <img src={Logo} width={"15%"} />
            <Card sx={{ width: "40%", mt: 3, border: "1px solid #e9e9e9" }}>
                <CardContent sx={{ p: 6 }}>
                    <Typography variant='h6' component={"h1"}>
                        Wortwechsel - Login
                    </Typography>
                    <Box component="form" onSubmit={onLogin} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Mitarbeiter-ID"
                            size='small'
                            name="username"
                            id="username"
                            autoCapitalize='none'
                            inputProps={{
                                autoCapitalize: 'none',
                                autoCorrect: 'off',
                            }}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Passwort"
                            size='small'
                            type={showPassword ? "text" : "password"}
                            id="password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {show2FACode && (
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="code"
                                label="Bestätigungscode"
                                size='small'
                                type={"text"}
                                id="code"
                            />
                        )}
                        {/* <FormControlLabel
                            sx={{mt: 1}}
                            control={<Checkbox value="remember" color="secondary" defaultChecked />}
                            label="Logindaten speichern"
                        /> */}
                        {show2FACode && (
                            <Button
                                onClick={() => {
                                    // generateLoginCode({ username: data.get('username') })
                                    setCounter(60);
                                }}
                                disabled={counter !== 0}
                                variant={"outlined"}
                                sx={{ mt: 3 }}
                                color="secondary"
                                size='small'
                                fullWidth
                            >
                                {counter !== 0 ? counter : "Bestätigungscode erneut senden"}
                            </Button>
                        )}
                        <LoadingButton
                            loading={loading}
                            color="secondary"
                            size='small'
                            variant="contained"
                            type='submit'
                            sx={{ mt: 1, mb: 2 }}
                            fullWidth
                        >
                            <span>Anmelden</span>
                        </LoadingButton>
                    </Box>
                </CardContent>
            </Card>
        </div>
    )
}