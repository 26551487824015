import { Table, TableBody, TableContainer } from "@mui/material"
import { CustomTableHead } from "./DefaultTableHead"
import { DefaultTableRow } from "./DefaultTableRow"
import { TableNoData } from "./TableNoData"

export const DefaultTable = ({ noData, showAll, noDataCaption, extraCellProps, singleSelection, withSelection, tableId, filter, idKey, data, columnDef, page, rowsPerPage }) => {
    const dataWindow = showAll ? data : data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <TableContainer sx={{ overflow: 'auto', display: "flex", flexGrow: 1, flexDirection: "column" }}>
            <Table stickyHeader sx={{ minWidth: "100%" }}>
                {noData ?
                    <TableNoData noDataCaption={noDataCaption} filter={filter} />
                    :
                    <CustomTableHead
                        rowCount={dataWindow.length}
                        headLabel={columnDef}
                    />
                }
                <TableBody>
                    {dataWindow.map((row) => (
                        <DefaultTableRow extraCellProps={extraCellProps} key={row[idKey]} withSelection={withSelection} singleSelection={singleSelection} tableId={tableId} id={row[idKey]} row={row} columnDef={columnDef} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}