import { fetcher } from ".";

export const getTimeTracksForEmployee = (employeeId) => {
    return fetcher("/api/timeRecords?" + new URLSearchParams({ employeeId }), {
        method: "GET",
        credentials: "include",
        headers: new Headers()
    }).then((res) => res.json());
}

export const getTimeTrackingTypes = () => {
    return fetcher("/api/timeTrackingTypes", {
        method: "GET",
        credentials: "include",
        headers: new Headers()
    }).then((res) => res.json());
}

export const setTimeRecord = (data) => {
    return fetcher("/api/timeRecord", {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const finishTimeRecord = (employeeId) => {
    return fetcher("/api/finishTimeRecord?" + new URLSearchParams({ employeeId }), {
        method: "POST",
        credentials: "include",
        headers: new Headers({ 'Content-Type': 'application/json' })
    });
}

export const getTimeTrackingStats = (employeeId) => {
    return fetcher("/api/timeTrackingStats?" + new URLSearchParams({ employeeId }), {
        method: "GET",
        credentials: "include",
        headers: new Headers()
    }).then((res) => res.json());
}