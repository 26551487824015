import { TableToolbar } from "../components/table/TableToolbar";
import { Box, Card, LinearProgress, Stack, Table, TableBody, TableContainer, TablePagination } from "@mui/material"
import { applyFilter, withTableHelper } from "../components/table/utils";
import { getEmployees, getProjects, getStates, setState } from "../api";
import { DATA_MODAL_COMPONENTS, DATA_MODAL_STATES } from "../components/dataModal/DataModal";
import { DefaultTable } from "../components/table/DefaultTable";
import { useSnackbar } from "notistack";

const columnDef = [
    { id: 'selection', label: '', align: 'center' },
    { id: 'id', disabled: true, label: 'ID', align: 'center' },
    { id: 'stateType', label: 'Statustyp', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'isFinished', required: false, type: "checkbox", label: 'Abgeschlossen?', align: 'center', comp: DATA_MODAL_COMPONENTS.CHECKBOX },
    { id: 'isResearch', required: false, type: "checkbox", label: 'Recherche?', align: 'center', comp: DATA_MODAL_COMPONENTS.CHECKBOX },
    { id: 'isNotAvailable', required: false, type: "checkbox", label: 'Nicht erreicht?', align: 'center', comp: DATA_MODAL_COMPONENTS.CHECKBOX },
];

export const _State = (props) => {
    const { filter, onFilterChange, onChangePage, onChangeRowsPerPage, data, page, rowsPerPage, refreshData } = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const onSave = ({ fieldValues, config }) => {
        return setState(fieldValues)
            .then(() => {
                return refreshData();
            })
            .catch((err) => {
                enqueueSnackbar("Beim Speichern des Status ist ein Fehler aufgetreten.", { variant: "error" });
                return Promise.reject();
            });
    }

    const dataForId = (id) => {
        return data.find(d => d.id === id);
    }

    return (
        <>
            <TableToolbar
                {...props}
                filterName={filter}
                onFilterName={onFilterChange}
                placeholder={"Abschlussstatus suchen..."}
                columnDef={columnDef}
                context={"Abschlussstatus"}
                onSave={onSave}
                getDataForId={dataForId}
            />
            <DefaultTable
                columnDef={columnDef}
                idKey={"id"}
                singleSelection
                {...props}
            />
            <TablePagination
                page={page}
                sx={{ minHeight: 50, maxHeight: 50 }}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                onPageChange={onChangePage}
                rowsPerPageOptions={[20, 30, 50]}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </>
    );
}


export const State = withTableHelper(_State, { loadData: getStates })