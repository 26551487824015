import { TableToolbar } from "../components/table/TableToolbar";
import { Box, Card, LinearProgress, Stack, Table, TableBody, TableContainer, TablePagination } from "@mui/material"
import { applyFilter, withTableHelper } from "../components/table/utils";
import { deleteEmployees, getEmployees, getProjects, setEmployee } from "../api";
import { DATA_MODAL_COMPONENTS, DATA_MODAL_STATES } from "../components/dataModal/DataModal";
import { DefaultTable } from "../components/table/DefaultTable";
import { sha512 } from "js-sha512";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { withWaitModal } from "../components/wait";

const columnDef = (projects) => [
    { id: 'selection', label: '', align: 'center' },
    { id: 'id', disabled: ({ config }) => config.modalState === DATA_MODAL_STATES.EDIT, label: 'Mitarbeiter-ID', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'firstname', label: 'Vorname', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'lastname', label: 'Nachname', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'password', disabled: ({ config }) => config.modalState === DATA_MODAL_STATES.EDIT, label: 'Passwort', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'email', label: 'E-Mail', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'mobile', required: false, label: 'Mobilnummer', align: 'center', comp: DATA_MODAL_COMPONENTS.TEXT_FIELD },
    { id: 'onlyProjectId', required: false, label: 'Zugewiesenes Projekt', align: 'center', comp: DATA_MODAL_COMPONENTS.SELECT, selectValues: [{ value: null, label: "Alle" }, ...projects.map(p => ({ value: p.id, label: p.projectName }))] },
    { id: 'admin', required: false, type: "checkbox", label: 'Admin', align: 'center', comp: DATA_MODAL_COMPONENTS.CHECKBOX },
    { id: 'researchPermission', required: false, type: "checkbox", label: 'Berechtigung Recherche', align: 'center', comp: DATA_MODAL_COMPONENTS.CHECKBOX },
    { id: 'active', required: false, type: "checkbox", label: 'Aktiv?', align: 'center', comp: DATA_MODAL_COMPONENTS.CHECKBOX },
];

export const _Employee = (props) => {
    const { filter, onFilterChange, onChangePage, onChangeRowsPerPage, getSelectedCells, data, page, rowsPerPage, refreshData } = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        getProjects().then(data => setProjects(data));
    }, []);

    const onSave = ({ fieldValues, config }) => {
        return setEmployee({ ...fieldValues, password: config.modalState === DATA_MODAL_STATES.EDIT ? fieldValues.password : sha512(fieldValues.password) })
            .then(() => {
                return refreshData();
            })
            .catch((err) => {
                enqueueSnackbar("Beim Speichern des Mitarbeiters ist ein Fehler aufgetreten.", { variant: "error" });
                return Promise.reject();
            });
    }

    const dataForId = (id) => {
        return data.find(d => d.id === id);
    }

    const onDelete = () => {
        if (window.confirm("Möchten Sie die ausgewählten Mitarbeiter wirklich löschen?")) {
            return withWaitModal(() => {
                return deleteEmployees(getSelectedCells())
                    .then(() => refreshData())
                    .catch(() => alert("Beim Löschen der Mitarbeiter ist ein Fehler aufgetreten."));
            });
        } else {
            return Promise.reject();
        }
    }

    return (
        <>
            <TableToolbar
                {...props}
                filterName={filter}
                onFilterName={onFilterChange}
                placeholder={"Mitarbeiter suchen..."}
                columnDef={columnDef(projects)}
                context={"Mitarbeiter"}
                onSave={onSave}
                // onDelete={onDelete}
                getDataForId={dataForId}
            />
            <DefaultTable
                columnDef={columnDef(projects)}
                idKey={"id"}
                singleSelection
                {...props}
            />
            <TablePagination
                page={page}
                sx={{ minHeight: 50, maxHeight: 50 }}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                onPageChange={onChangePage}
                rowsPerPageOptions={[20, 30, 50]}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </>
    );
}


export const Employee = withTableHelper(_Employee, { loadData: getEmployees })