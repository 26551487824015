import * as React from 'react';
import { CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, TimeScale, CategoryScale, PointElement, BarElement, LineElement } from 'chart.js';
import { Bar, Chart, Doughnut, Line } from 'react-chartjs-2';
import { store } from '../reducers/store';
import moment from 'moment';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import { useState } from 'react';
import { useEffect } from 'react';
import { getResubmissions, getTimeTrackingStats } from '../api';

import "react-big-calendar/lib/css/react-big-calendar.css";

import "./styles/customCalendar.scss";

const localizer = momentLocalizer(moment)

const TextItem = ({ caption, value = "", captionStyle = {} }) => {
    return (
        <tr>
            <td style={{ verticalAlign: "top" }}>
                <Typography variant="body2" sx={captionStyle}>
                    {caption}
                </Typography>
            </td>
            <td style={{ verticalAlign: "top" }}>
                <Typography variant="body2" fontWeight={600}>
                    {value}
                </Typography>
            </td>
        </tr>
    )
}

export default function Dashboard() {
    const user = store((state) => state.appState.user);
    const changeRoute = store((state) => state.appState.changeRoute);
    const timeTrackingRefresh = store((state) => state.appState.timeTrackingRefresh);

    const [resubmissions, setResubmissions] = useState();
    const [timeTrackingStats, setTimeTrackingStats] = useState();

    useEffect(() => {
        Promise.all([getResubmissions(user.id), getTimeTrackingStats(user.id)]).then((data) => {
            setTimeTrackingStats(data[1]);
            setResubmissions(data[0].map(r => {
                return {
                    id: r.id,
                    title: "WV für " + r.customerProjectPool.customer.name1,
                    start: new Date(r.resubmissionDate),
                    end: new Date(moment(r.resubmissionDate).add(1, "hour"))
                }
            }));
        });
    }, []);

    useEffect(() => {
        getTimeTrackingStats(user.id).then((data) => {
            setTimeTrackingStats(data);
        });
    }, [timeTrackingRefresh]);

    return (
        <Grid height={"100%"} container spacing={1} overflow={"auto"}>
            <Grid item xs={12} md={3} lg={3}>
                <Grid container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflow: "auto" }}>
                            <Typography variant='h6'>
                                Hallo {user.firstname} {user.lastname}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflow: "auto" }}>
                            <Typography variant='h6'>
                                Zeitkonto
                            </Typography>
                            {!timeTrackingStats ? (
                                <CircularProgress />
                            ) : (
                                <table style={{ borderCollapse: "separate", borderSpacing: "0px 10px" }}>
                                    {Object.keys(timeTrackingStats).map((month) => {
                                        const monthSum = timeTrackingStats[month]["anwesend"]?.reduce((sum, val) => sum + val.timeDiff, 0) || 0;
                                        const hours = Math.floor(monthSum / 60);
                                        const minutes = monthSum % 60;
                                        const monthSumString = (String(hours).length < 2 ? "0" + hours : hours) + ":" + (String(minutes).length < 2 ? "0" + minutes : minutes) + " Stunden"
                                        return (
                                            <TextItem captionStyle={month === moment().format("MMMM") ? { color: "#3174ad", fontWeight: "600" } : {}} caption={month + " " + moment().format("YYYY")} value={monthSumString} />
                                        )
                                    })}
                                    {/* <TextItem caption={"Resturlaub"} value={""} />
                                    <TextItem caption={"Krankheitstage"} value={""} /> */}
                                </table>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflow: "auto" }}>
                            <Typography variant='h6'>
                                Urlaub
                            </Typography>
                            {!timeTrackingStats ? (
                                <CircularProgress />
                            ) : (
                                <table style={{ borderCollapse: "separate", borderSpacing: "0px 10px" }}>
                                    {Object.keys(timeTrackingStats).map((month) => {
                                        const vacations = timeTrackingStats[month]["urlaub"] || [];
                                        return vacations.map(v => {
                                            const days = moment(v.endTime).diff(moment(v.startTime), "days")
                                            return (
                                                <TextItem caption={moment(v.startTime).format("DD.MM.YYYY") + " - " + moment(v.endTime).format("DD.MM.YYYY")} value={days} />
                                            )
                                        });
                                    })}
                                </table>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={9} xl={9}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflow: "auto" }}>
                    <Typography variant='h6' sx={{ mb: 3 }}>
                        Deine Wiedervorlagen
                    </Typography>
                    {!resubmissions ? (
                        <CircularProgress />
                    ) : (
                        <Calendar
                            localizer={localizer}
                            events={resubmissions}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 500 }}
                            views={[Views.MONTH, Views.DAY]}
                            onSelectEvent={(event) => changeRoute("task", { context: "resubmission", resubmissionId: event.id })}
                            messages={{
                                week: 'Woche',
                                work_week: 'Arbeitswoche',
                                day: 'Tag',
                                month: 'Monat',
                                previous: 'Vorheriger',
                                next: 'Nächster',
                                today: 'Heute',
                                agenda: 'Agenda',

                                showMore: (total) => `+${total} weitere`,
                            }}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
}